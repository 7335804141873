import digitalNomad1 from '../images/digital-nomad-1.jpg'
import globalStyles from '../App.css'

const EU_COUNTRIES = {
    'AT': 'Austria',
    'BE': 'Belgium',
    'BG': 'Bulgaria',
    'HR': 'Croatia',
    'CY': 'Cyprus',
    'CZ': 'Czech Republic',
    'DK': 'Denmark',
    'EE': 'Estonia',
    'FI': 'Finland',
    'FR': 'France',
    'DE': 'Germany',
    'GR': 'Greece',
    'HU': 'Hungary',
    'IE': 'Ireland',
    'IT': 'Italy',
    'LV': 'Latvia',
    'LT': 'Lithuania',
    'LU': 'Luxembourg',
    'MT': 'Malta',
    'NL': 'Netherlands',
    'PL': 'Poland',
    'PT': 'Portugal',
    'RO': 'Romania',
    'SK': 'Slovakia',
    'SI': 'Slovenia',
    'ES': 'Spain',
    'SE': 'Sweden',
    'IS':'Iceland', 
    'LI':'Liechtenstein',
    'NO':'Norway'
  }

const nomadVisaAvailable = ({passportCountries, employerCountry, estimatedIncome})=>{
    const EU_COUNTRY_VALUES = Object.keys(EU_COUNTRIES)
    const HAS_EU_PASSPORT = EU_COUNTRY_VALUES.map((EuCountry)=>{ return passportCountries.includes(EuCountry) }).includes(true)

    if(HAS_EU_PASSPORT) return false 
    if(employerCountry === 'Spain') return false 
    if(estimatedIncome && estimatedIncome < 27115.20) return false

    return true
}

const nomadVisaContent = ()=>{
          return(
          <div className={'documentInformationContent'}>
            <div className={'documentInformationText'}>
              <div className={'documentInformationTitle'}>Spanish Digital Nomad Visa</div>
              The Spanish Digital Nomad Visa was launched in January 2023. It is a special visa available to non-EU citizens.
              <br/><br/>
              You must have a degree or at least 3 years of experience in your acitvity. You may work for a Spanish company, but it may only represent less than 20% of your income.
              <br/><br/>
              The Digital Nomad Visa or "Beckham's Law" allows you to pay a flat tax rate of 24% in Spanish-sourced income for 6 years. Our accountants can provide you with detailed information for your case.
              <br/><br/>
              More information can be found at <a href='https://www.exteriores.gob.es/Consulados/londres/en/ServiciosConsulares/Paginas/Consular/Digital-Nomad-Visa.aspx'>the official government website</a>.
            </div>
            <img src={digitalNomad1} className={'documentInformationImage'} />
          </div>
          )
}

export {nomadVisaAvailable, nomadVisaContent}
