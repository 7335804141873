import React, {useState, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"

// Styles
import styles from './index.module.scss';

// Assets
import defaultProfilePicture from '../../images/user.png'

export const Header = ({name, profilePicture, title, emojiName})=>{
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  return(
    <EmojiProvider data={emojiData}>
       <div className={styles.header}>
          <div className={styles.headerWelcome}>
            <Emoji name={emojiName || "waving-hand"} className={styles.emoji} width={28} /> {(title)?title:'Welcome Back, '+name}
          </div>
          <div className={styles.headerProfile}>
            <a href="./settings">
              <img src={profilePicture || defaultProfilePicture} className={styles.headerProfileImage} />
            </a>
          </div>
        </div>
    </EmojiProvider>
   
  )


}
