import React, {useState, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
// Styles
import styles from './index.module.scss';

// Modules
import Cookies from 'universal-cookie';

//Views
import {SideMenuItem} from '../SideMenuItem';


// Assets
import logo from '../../images/logo.png';
import home from '../../images/home.png';
import settings from '../../images/settings.png';
import messages from '../../images/message.png';
import content from '../../images/work-icon-3.png';
import documents from '../../images/flow-icon-4.png';
import invoices from '../../images/work-icon-4.png';

import store from '../../store'
import authSlice from '../../slices/authSlice'

export const ClientMenu = ({activeScreen})=>{
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const logout = useCallback(async()=>{
    const cookies = new Cookies();
    let expiryDate = new Date(new Date().getTime() - (9 * 24 * 3600 * 1000));
    await cookies.set('token', '', { path: '/', expires:expiryDate});
    store.dispatch(authSlice.actions.logout())
    navigate('/login');
  },[]);

  return(
    <div class={styles.sideMenu}>
      <img className={styles.sideMenuLogo} src={logo} />
      <div className={styles.sideMenuContent}>
        <SideMenuItem href="../" text="Home" icon={home} isActive={(activeScreen === "Dashboard")} />
        <SideMenuItem href="./documents" text="My Documents" icon={documents} isActive={(activeScreen === "Documents")} />
        <SideMenuItem href="./messages" text="Messages" icon={messages} isActive={(activeScreen === "Messages")}/>
        <SideMenuItem href="./content" text="Helpful Guides" icon={content} isActive={(activeScreen === "Content")} />
        <SideMenuItem href="./invoices" text="My Invoices" icon={invoices} isActive={(activeScreen === "Invoices")} />
        <SideMenuItem href="./settings" text="Settings" icon={settings} isActive={(activeScreen === "Settings")} />
      </div>
      <div className={styles.sideMenuLogoutButton} onClick={logout}>
        <div className={styles.sideMenuLogoutButtonText}>Logout</div>
      </div>
    </div>
  )


}
