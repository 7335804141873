import React, {useState, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"

// Styles
import styles from './index.module.scss';

// Assets
import content from '../../images/work-icon-3.png';
import { contentSections } from '../../utils/contentSections.js';

const ContentSection = ({title, background, color, id})=>{
  return(
    <a href={'./content?topic='+id}>
      <div className={styles.contentSection}>
        <img src={background} className={styles.contentSectionImage} />
        <div className={styles.contentSectionFooter} style={{background:"linear-gradient(0deg,"+color+"FF,"+color+"00)"}}></div>
        <div className={styles.contentSectionTitle}>{title}</div>
      </div>
    </a>
  )
}



export const ContentTile = ({})=>{
  const accountant = useSelector(state => state.authSlice?.accountant)
  //const accountant = {name:'Miguel Hernandez'}
  const user = useSelector(state => state.authSlice?.user)
  const navigate = useNavigate();
  

  return(
       <div className={styles.tile}>
          <div className={styles.title}><img src={content} className={styles.titleIcon} />Helpful Digital Nomad Guides</div>
          <div className={styles.tileContent}>
            {
              contentSections.map((section)=>{
                return <ContentSection id={section.id} background={section.background} title={section.title} color={section.color} />
              })
            }
          </div>
        </div>
   
  )


}
