import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import styles from './styles.module.scss';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import closeIcon from '../../images/close.svg'
import store from '../../store'
import principalSlice from '../../slices/principalSlice'
import { SyncLoader } from 'react-spinners'

export const ChatBot = ({top}) => {
  const [loading, setLoading] = useState(false);
  const [frankOpen, setFrankOpen] = useState(false);
  const messages = useSelector(state => state.principalSlice?.assistantMessages)
  const [inputText, setInputText] = useState('');

  const renderBox = ()=>{
    if(!frankOpen) return;

    return(
      <div className={styles.box}>
        <div className={styles.header}>
          <Emoji name={"beaming-face-with-smiling-eyes"} className={styles.emoji} width={28} /> SimplyNomad AI
        </div>
        <img src={closeIcon} className={styles.close} onClick={(e)=>{setFrankOpen(false)}} />
        <div id="chatbot-messages" className={styles.messages}>
          <div className={styles.chatbotMessageContainer}>
            <div className={styles.chatbotMessage}>
              Hey! I can help you with queries about our platform or getting started with the tax system in Spain.
              <br/>For example: 'How do I get my NIE number?'
              <br/>Note: Some information may be inaccurate. Always consult your accountant.
            </div>
          </div>
        {
          messages.map((message,messageIndex)=>{
              return(
                <div className={(message.sender == 'assistant')?styles.chatbotMessageContainer:styles.myMessageContainer}>
                  <div className={(message.sender == 'assistant')?styles.chatbotMessage:styles.myMessage}>
                    {message.text}
                  </div>
                </div>
              )
          })
        }
        {
          (loading)?
            <div className={styles.loaderContainer}>
              <SyncLoader speedMultiplier={0.4} color={'#446BF7'} size={14} />
            </div>
          :
          <></>
        }
        </div>
        <div className={styles.inputContainer}>
            <form onSubmit={async(event)=>{
              event.preventDefault();

              const cookies = new Cookies()
              await setLoading(true)
              await store.dispatch(principalSlice.actions.pushMessage(inputText)) 
              const query = inputText
              setInputText('')
              let res = await store.dispatch(principalSlice.async$.callAssistant({query})) 
              console.log(res)

              //const res = await store.dispatch(principalSlice.async$.callAssistant({query:inputText}))


              setLoading(false)
            }}>
            <input placeholder={"Type a query..."} type="text" className={styles.input} value={inputText} onChange={(event)=>{setInputText(event.target.value)}} />
            </form>
        </div>
      </div>
    )
  }

  return (
    <EmojiProvider data={emojiData}>
      <div className={styles.container}>
        {(top)?<></>:renderBox()}
        <div className={styles.assistantButton} onClick={(e)=>{ setFrankOpen(!frankOpen) }}>
          Instant Assistant <Emoji name={"beaming-face-with-smiling-eyes"} className={styles.emoji} width={28} />
        </div>
        {(top)?renderBox():<></>}
      </div>
    </EmojiProvider>
  )
}
