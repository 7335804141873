
export const getLatestMessage = (messages, client)=>{
    let clientMessages = messages.filter((message)=>{ return (message.clientId) === client.id }).sort((a,b)=>{ return a.sent - b.sent })
    let lastMessage = clientMessages[clientMessages.length - 1]

    return lastMessage
}

export const getLastMessageShortened = (messages, client)=>{
    let lastMessage = getLatestMessage(messages, client)

    return (lastMessage?.text.length > 20)?((lastMessage?.text.substring(0, 20))+'...'):(lastMessage?.text)
}

export const orderClientsByMessages = (messages, clients)=>{
    return clients.sort((a, b)=>{
        return getLatestMessage(messages, b).sent - getLatestMessage(messages, a).sent
    })
}