import React, {useState, useCallback, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import Select from "react-select"
import { ClipLoader } from "react-spinners"

import store from '../../store'
import authSlice from '../../slices/authSlice'

// Styles
import styles from './index.module.scss';

// Assets
import invoices from '../../images/work-icon-4.png';

const Range = ({ref, key, question, onChange, value, placeholder, currency})=>{
  return(
    <div className="radio">
        <div className="radioQuestion">{question}</div>
        <div className="radioOptions">
          <div className="rangeCurrency">{currency}</div>
          <input key={key} ref={ref} id={key} type="number" className="rangeInput" placeholder={placeholder} value={value} onChange={(event)=>{ onChange(event.target.value) }} />
        </div>
    </div>
  )
}

const CountrySelect = ({question, value, onChange, countries, isMulti})=>{
  return(
    <div className="radio">
        <div className="radioQuestion">{question}</div>
        <Select
          isMulti={isMulti}
          name="colors"
          options={countries}
          className="countryInput"
          value={value}
          isSearchable={true}
          onChange={(val) => onChange(val)}
          menuPortalTarget={document.querySelector('body')}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
    </div>
  )
}


const Radio = ({question, options, onChange, selected})=>{
  return(
    <div className="radio">
        <div className="radioQuestion">{question}</div>
        <div className="radioOptions">
          {
            options.map((option)=>{
              return(
                (option === selected)?
                <div className="radioOptionSelected">{option}</div>
                :
                <div className="radioOption" onClick={(e)=>{ onChange(option) }}>{option}</div>
              )
            })
          }
        </div>
    </div>
  )
}


export const MonthlyOverview = ({})=>{
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const estimatedIncome = useSelector(state => state.authSlice?.estimatedIncome)
  const workType = useSelector(state => state.authSlice?.workType)
  const country = useSelector(state => state.authSlice?.country)
  const employerCountry = useSelector(state => state.authSlice?.employerCountry)
  const accountant = useSelector(state => state.authSlice?.accountant)
  const incomeRef = useRef(null)
  //const accountant = {name:'Miguel Hernandez'}
  const user = useSelector(state => state.authSlice?.user)
  const navigate = useNavigate()

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries)
        console.log(data.countries)
      })
  }, [])

  const updateProfile = async()=>{
    await setLoading(true)

    const res = await store.dispatch(authSlice.async$.updateProfile({estimatedIncome,workType,country,employerCountry}))

    console.log(res.data)

    setLoading(false)
  }
  

  return(
    <div>
      {(accountant)?
        <EmojiProvider data={emojiData}>
          <div className={styles.tile}>
              <div className={styles.title}><img src={invoices} className={styles.titleIcon} /> My Monthly Overview</div>
              <br/>
                <div className={styles.content}>
                  
                </div>
            </div>
        </EmojiProvider>
        :
        <EmojiProvider data={emojiData}>
          <div className={styles.tile}>
              <div className={styles.title}><img src={invoices} className={styles.titleIcon} /> My Details</div>
              <br/>
                <div className={styles.content}>
                  <Radio question={'How Would You Describe Your Work?'} options={['I Am A Freelancer','I Own A Company','I Am An Employee']} selected={workType} onChange={(value)=>{ store.dispatch(authSlice.actions.setWorkType(value)) }} />
                  {(workType === 'I Am A Freelancer')?<></>:<CountrySelect isMulti={false} question={'Where Is Your Employer / Company Located?'} countries={countries} value={countries.filter((country)=>{ return country.value === employerCountry})[0]} onChange={(option)=>{store.dispatch(authSlice.actions.setEmployerCountry(option.value))}} />}
                  <Range ref={incomeRef} key={'dash-income-input'} currency={'€'} question={'What Is Your Gross Annual Income?'} value={estimatedIncome} onChange={async(value)=>{ 
                    await store.dispatch(authSlice.actions.setEstimatedIncome(value)) 
                   
                    }} />
                  <CountrySelect isMulti={true} question={'Which Countries Do You Hold A Passport From?'} countries={countries} value={country.map((c)=>{
                    return countries.filter((option)=>{ return option.value === c })[0]
                  })} onChange={(option)=>{ store.dispatch(authSlice.actions.setCountry(option.map((o)=>{ return o.value }))) }} />
                  <br/><br/>
                  <div className={styles.submit}>
                    {(loading)?
                    <ClipLoader size={50} color={'#5603fc'} />
                    :
                    <div className={styles.saveButton} onClick={(e)=>{ updateProfile() }}>
                    Save <Emoji name="bookmark-tabs" className={styles.emoji} width={18} />
                    </div>
                    }
                  </div>
                </div>
            </div>
        </EmojiProvider>
        }
    </div>
  )


}
