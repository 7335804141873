
import React,{ useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { ClientMenu } from '../../components/ClientMenu';
import { Header } from '../../components/Header';
import { MyAccountantTile } from '../../components/MyAccountantTile';
import { ContentTile } from '../../components/ContentTile';
import { DocumentsOverview } from '../../components/DocumentsOverview';
import { PopupWindow } from '../../components/PopupWindow';
import styles from './styles.module.scss'
import { MonthlyOverview } from '../../components/MonthlyOverview';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import { ChatBot } from '../../components/ChatBot'

export const Client = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.authSlice?.user)
  const [content, setContent] = useState(null)

  useEffect(()=>{
    console.log(user)
  },[]);



  return (
    <div className={styles.screen}>
        <ClientMenu activeScreen={'Dashboard'} />
        <div className={styles.content}>
          <Header name={user?.name} />
          <div className={styles.tiles}>
            <div className={styles.column}>
              <MyAccountantTile />
              <DocumentsOverview setInfoWindowContent={(content)=>{ setContent(content) }} />
            </div>
            <div className={styles.column}>
              <ContentTile />
              <MonthlyOverview />
            </div>
          </div>
        </div>
        <PopupWindow windowStyles={{width:'60%'}} title={<EmojiProvider data={emojiData}> Document Information<Emoji name="globe-showing-europe-africa" className={styles.emoji} width={22} /></EmojiProvider>} onClose={()=>{ setContent(null) }} open={content}>{content}</PopupWindow>
        <ChatBot />
    </div>
  )
}