import React,{useState, forwardRef, useImperativeHandle} from 'react';

// Styles
import styles from './index.module.scss';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { GridLoader } from "react-spinners";
import { withAlert } from 'react-alert';
import Cookies from 'universal-cookie';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import xButton from '../../images/x-button.png';
//Views

//Services & Local Logic

// Assets

// Animations

export const TextInput = forwardRef(({customStyle, type,placeholder,onChange, onChangeText, noHeader,onClick,icon,options,initialValue,border,large,products}, ref)=>{

  const [value,setValue] = useState(initialValue || '');
  const [unfocused,setUnfocused] = useState(true);
  const [selected,setSelected] = useState(false);

  useImperativeHandle(ref, () => ({

    setTextboxValue:(newValue)=>{
      setValue(newValue);
      setSelected(true);
    }

  }));

  switch(type){
    case('password'):
      return(
        <input type="password" className={styles.textInput} value={value} onChange={(event)=>{setValue(event.target.value); onChange(event.target.value)}} placeholder={placeholder} />
      )
    break;

    case('email'):
      return(
        <input type="text" style={{...customStyle}} className={styles.textInput} value={value} onChange={(event)=>{setValue(event.target.value); onChange(event.target.value)}} placeholder={placeholder} />
      )
    break;

    case('date'):
      return(
        <span className={styles.customInputDateSpan}>
          <span className={styles.customInputDatePickerTitle}>{placeholder}</span>
          <DatePicker onChange={(event)=>{setValue(event); onChange(event)}} selected={value} className={styles.customInputDate} dateFormat="dd/MM/yyyy" calendarClassName="rasta-stripes" />
        </span>
      )
    break;



    case('filter-text'):
      return(
        <span className={styles.customInputFilterSpan}>
          {(noHeader)?<></>:<div className={styles.customInputDateTitle}>{placeholder}</div>}
          <input type="text" onChange={(event)=>{setValue(event.target.value); onChange(event)}} placeholder={placeholder} className={styles.customInputDate} value={value}   />
        </span>
      )
    break;


    case('plain-search'):
      return(
        <span className={styles.customInputFilterSpan}>
          {(noHeader)?<></>:<div className={styles.customInputDateTitle}>{placeholder}</div>}
          <div className={styles.customInputContainer}>
            <img src={require('../../images/search-icon.png')} className={styles.customInputIcon} />
            <input type="text" onChange={(event)=>{setValue(event.target.value); onChange(event)}} placeholder={placeholder} className={styles.customSearchInput} value={value}   />
          </div>
        </span>
      )
    break;

    case('submit-input'):

      if(large){
        return(
          <div style={{border:(border)?"1px solid white":"0px solid white"}} className={styles.submitInputLarge} onClick={(event)=>{onClick(event)}}>
            <img src={icon} className={styles.submitInputIcon} />
            {placeholder}
          </div>
        )
      }else{
        return(
          <div style={{border:(border)?"1px solid white":"0px solid white"}} className={styles.submitInput} onClick={(event)=>{onClick(event)}}>
            <img src={icon} className={styles.submitInputIcon} />
            {placeholder}
          </div>
        )
      }
    break;

    case('submit-input-print'):

      if(large){
        return(
          <div style={{border:(border)?"1px solid white":"0px solid white", background:'#b8b7eb'}} className={styles.submitInputLarge} onClick={(event)=>{onClick(event)}}>
            <img src={icon} className={styles.submitInputIcon} />
            {placeholder}
          </div>
        )
      }else{
        return(
          <div style={{border:(border)?"1px solid white":"0px solid white", background:'#b8b7eb'}} className={styles.submitInput} onClick={(event)=>{onClick(event)}}>
            <img src={icon} className={styles.submitInputIcon} />
            {placeholder}
          </div>
        )
      }
    break;

    case('submit-input-purple'):
    if(large){
      return(
        <div style={{border:(border)?"1px solid white":"0px solid white"}} className={styles.submitInputLargePurple} onClick={(event)=>{onClick(event)}}>
          <img src={icon} className={styles.submitInputIcon} />
          {placeholder}
        </div>
      )
    }
      else{
        return(
          <div className={styles.submitInputPurple} onClick={(event)=>{onClick(event)}}>
            <img src={icon} className={styles.submitInputIcon} />
            {placeholder}
          </div>
        )
      }
    break;

    case('form-text'):
      return(
        <span style={customStyle} className={styles.formInputSpan}>
          <div className={styles.formInputTitle}>{placeholder}</div>
          <input type="text" className={styles.formInput} value={value} onChange={(event)=>{setValue(event.target.value); onChange(event)}} placeholder={placeholder} />
        </span>
      )
    break;

    case('form-search'):
      return(
        <span className={styles.formInputSpan}>
          <div className={styles.formInputTitle}>{placeholder}</div>
          <input style={{background:(selected)?'#51c993':'#fcb586', color:(selected)?'white':'white'}} onFocus={(event)=>{setUnfocused(false)}} onBlur={(event)=>{}} type="text" className={styles.formInput} value={value} onChange={(event)=>{setValue(event.target.value); onChangeText(event.target.value);}} placeholder={''} />
          {(unfocused)?<></>:<div className={styles.searchOptions}>
            <img src={xButton} onClick={(event)=>{setUnfocused(true)}}  style={{width:"12px", marginLeft:"-20px",opacity:"0.8"}}/><br/>
            {
              options.map((option, optionIndex)=>{
                return(
                  <div className={styles.searchOption} onClick={(event)=>{
                    setSelected(true);
                    setUnfocused(true);
                    setValue((products)?option.proprietary:option.name);
                    onChange(option)}}>
                    {(products)?option.proprietary:option.name} - {(products)?option.nonProprietary:option.address}
                  </div>
                )
              })
            }
            </div>
          }
        </span>
      )
    break;

    case('form-number'):
      return(
        <span className={styles.formInputSpan}>
          <div className={styles.formInputTitle}>{placeholder}</div>
          <input type="number" className={styles.formInput} value={value} onChange={(event)=>{setValue(event.target.value); onChange(event)}} placeholder={placeholder} />
        </span>
      )
    break;

    case('form-text-small'):
      return(
        <span className={styles.formInputSpan}>
          <div className={styles.formInputTitleSmall}>{placeholder}</div>
          <input type="text" className={styles.formInputSmall} value={value} onChange={(event)=>{setValue(event.target.value); onChange(event)}} placeholder={placeholder} />
        </span>
      )
    break;

    case('form-number-small'):
      return(
        <span className={styles.formInputSpan}>
          <div className={styles.formInputTitleSmall}>{placeholder}</div>
          <input type="number" className={styles.formInputSmall} value={value} onChange={(event)=>{setValue(event.target.value); onChange(event)}} placeholder={placeholder} />
        </span>
      )
    break;

    case('form-checkbox'):
      return(
        <span className={styles.formInputCheckboxSpan}>
          <input type="checkbox" checked={value} className={styles.formCheckboxInput} value={value} onChange={(event)=>{setValue(!value); onChange(event)}} placeholder={placeholder} />
          <div className={styles.formInputCheckboxTitle}>{placeholder}</div>
        </span>
      )
    break;

    case('dropdown'):
      return(
        <span className={styles.dropdownInputSpan} style={customStyle}>
          <div className={styles.formInputTitle}>{(noHeader)?'':placeholder}</div>
          <select  value={value} onChange={(event)=>{setValue(event.target.value); onChange(event.target.value)}}  className={styles.dropdownInput}>
            <option disabled={false} key="Please Select" value={'NO_SELECTION'}>Please Select</option>
            {
              options.map((option)=> <option key={option} value={option}>{option}</option>)
            }
          </select>
        </span>
      )
    break;

    case('dropdown-mandatory'):
      return(
        <span className={styles.dropdownInputSpan} style={customStyle}>
          <div className={styles.formInputTitle}>{(noHeader)?'':placeholder}</div>
          <select  value={value} onChange={(event)=>{setValue(event.target.value); onChange(event.target.value)}}  className={styles.dropdownInput}>
            {
              options.map((option)=> <option key={option} value={option}>{option}</option>)
            }
          </select>
        </span>
      )
    break;

    case('dates-dropdown'):

      const customStylesNone = {
        border:'0px',
        padding:'2px'
      }


      return(
        <span className={styles.dropdownInputSpan}>
          <div className={styles.formInputTitle}>{placeholder}</div>
          <select  value={value} onChange={(event)=>{setValue(event.target.value); onChange(event.target.value)}}  className={styles.dropdownInput}>
            <option disabled={false} key="Please Select" value={'NO_SELECTION'}>Please Select</option>
            {
              options.map((option)=> <option key={option.name} value={option.time}>{option.name}</option>)
            }
          </select>
        </span>
      )
    break;

    case('products-dropdown'):
      /* return(
        <span className={styles.dropdownInputSpan}>
          <div className={styles.formInputTitle}>{placeholder}</div>
          <select  value={initialValue} onChange={(event)=>{setValue(event.target.value); onChange(event.target.value)}}  className={styles.dropdownInput}>
            <option disabled={false} key="Please Select" value={'NO_SELECTION'}>Please Select</option>
            {
              options.map((option)=> <option key={option.name} value={option.id}>{option.name}</option>)
            }
          </select>
        </span>
      ) */

      const customStyles = {
        border:'0px'
      }

      return(
        <span className={styles.dropdownInputSpan}>
          <div className={styles.formInputTitle}>{placeholder}</div>
          <Select
            className={styles.dropdownInput}
            styles={customStyles}
            classNamePrefix="select"
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={true}
            value={initialValue}
            name={placeholder}
            options={[{name:"No Selection",id:"NO_SELECTION"}].concat(options).map((option)=> {return {label:option.name,value:option.id}; } )}
            onChange={(newValue)=>{setValue(newValue); onChange(newValue)}}
          />
        </span>
      )
    break;

    default:
      return(
        <input type="text" className={styles.textInput} value={value} onChange={(event)=>{setValue(event.target.value); onChange(event.target.value)}} placeholder={placeholder} />
      )
    break;
  }

})
