import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import getSuggestedDocuments from '../../utils/getSuggestedDocuments';
import {nomadVisaAvailable, nomadVisaContent } from '../../utils/nomadVisaAvailable';

// Styles
import styles from './index.module.scss';

// Assets
import documents from '../../images/flow-icon-4.png';


export const DocumentsOverview = ({setInfoWindowContent})=>{

  const accountant = useSelector(state => state.authSlice?.accountant)
  //const accountant = {name:'Miguel Hernandez'}
  const user = useSelector(state => state.authSlice?.user)
  const navigate = useNavigate();
  const [countries, setCountries] = useState([])
  
  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries)
      })

    console.log(user)
  }, [])

  return(
    <EmojiProvider data={emojiData}>
       <div className={styles.tile}>
          <div className={styles.title}><img src={documents} className={styles.titleIcon} />Recommended Documents For You</div>
          <br/>
          {
            (false)?
            <div className={styles.content}>

            </div>
            :
            <div className={styles.content}>
              <div className={styles.tileSubtitle}>Don't worry if you don't have everything prepared right away. Our accountants will help guide you in your specific situation!</div>
              <div className={styles.documents}>
                {
                  (nomadVisaAvailable({passportCountries:JSON.parse(user.passports || '[]'),employerCountry:user.employerCountry,estimatedIncome:user.estimatedIncome}))?
                  <div className={styles.nomadDocument}>
                    <div className={styles.documentsName}>You May Be Applicable For The Spanish Digital Nomad Visa!</div>
                    <div className={styles.nomadDocumentsButton} onClick={(e)=>{ setInfoWindowContent(nomadVisaContent()) }}>
                      More Info <Emoji name="rocket" className={styles.emoji} width={16} />
                    </div>
                  </div>
                  :
                  <></>
                }
                {
                  getSuggestedDocuments({passportCountries:JSON.parse(user.passports || '[]')}).map((document)=>{
                    return(
                      <div className={styles.document}>
                        <div className={styles.documentsName}>{document.name}</div>
                        <div className={styles.documentsButton} onClick={(e)=>{ setInfoWindowContent(document.information) }}>
                          More Info
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          }
        </div>
    </EmojiProvider>
   
  )


}
