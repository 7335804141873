
import React,{ useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { ClientMenu } from '../../components/ClientMenu';
import { Header } from '../../components/Header';
import styles from './styles.module.scss'
import { socket } from '../../utils/socket'
import { getSentTimeLocal } from '../../utils/getSentTimeLocal'
import Cookies from "universal-cookie"
import store from '../../store'
import principalSlice from '../../slices/principalSlice'

// Assets
import defaultProfilePicture from '../../images/user.png'

export const Client = () => {
  const navigate = useNavigate();
  const accountant = useSelector(state => state.authSlice?.accountant)
  const user = useSelector(state => state.authSlice?.user)
  const messages = useSelector(state => state.principalSlice?.messages)
  const [inputText, setInputText] = useState([])
  

  const initSocket = async()=>{
    const cookies = new Cookies()
    const token = await cookies.get("token")
    await store.dispatch(principalSlice.async$.getMessages())
    socket.auth = { token }
    socket.on("connect", () => { console.log(`User connected: ${socket.id}`) })
    socket.on("disconnect", () => { console.log(`User disconnected: ${socket.id}`) })
    socket.on('send-message',async(message) => {
      let elem = document.getElementById('client-messages');
      const isAtBottom = elem.scrollTop == (elem.scrollHeight - elem.offsetHeight);
      console.log(elem.scrollTop)
      console.log(elem.scrollHeight)
      await store.dispatch(principalSlice.actions.pushAccountantMessage(message))
      if(isAtBottom) elem.scrollTop = elem.scrollHeight
    })
    await socket.connect()
    let elem = document.getElementById('client-messages');
    elem.scrollTop = elem.scrollHeight;
  }

  const sendMessage = ()=>{
    socket.emit("send-message", {
      content:inputText,
      to: user.id
    })
  }

  useEffect(()=>{
    initSocket()
  },[])



  return (
    <div className={styles.screen}>
        <ClientMenu activeScreen={'Messages'} />
        <div className={styles.content}>
          <Header name={user?.name} />
          <div className={styles.messages}>
            <div className={styles.messagesWindow}>
              <div className={styles.messagesHeader}>
                <img src={accountant?.profileImage || defaultProfilePicture} className={styles.profileImage} />
                {(accountant)?'Messages With '+accountant?.name:'No Accountant'}
              </div>
              <div id='client-messages' className={styles.messagesContainer}>
                {
                  messages.map((message)=>{
                    return(
                      <div className={(message.senderId === 1)?styles.sentMessageRow:styles.receivedMessageRow}>
                        <div className={(message.senderId === 1)?styles.sentMessage:styles.receivedMessage}>{message.text}</div>
                        <div className={(message.senderId === 1)?styles.sentTimeRight:styles.sentTimeLeft}>{getSentTimeLocal(message.sent)}</div>
                      </div>
                    )
                  })
                }
              </div>
              <div className={styles.inputContainer}>
                <form className={styles.form} onSubmit={async(event)=>{
                  event.preventDefault()
                  sendMessage()
                  setInputText('')
                  document.getElementById('message-textbox').value = ''
                }}>
                  <input id='message-textbox' placeholder={"Type a message..."} type="text" className={styles.input} value={inputText} onChange={(event)=>{setInputText(event.target.value)}} />
                  <input type='submit' className={styles.sendMessage} value={'Send'} />
                </form>
            </div>
            </div>
          </div>
        </div>
     </div>
  )
}