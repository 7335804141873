
import React,{ useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { AccountantMenu } from '../../components/AccountantMenu';
import { Header } from '../../components/Header';
import styles from './styles.module.scss'
import { socket } from '../../utils/socket'
import Cookies from "universal-cookie"
import store from '../../store'
import principalSlice from '../../slices/principalSlice'
import filterClients from '../../utils/filterClients'
import { getLastMessageShortened, orderClientsByMessages } from '../../utils/getLatestMessage'
import { getSentTimeLocal } from '../../utils/getSentTimeLocal'
import { TextInput } from '../../components/TextInput'

// Assets
import defaultProfilePicture from '../../images/user.png'

export const Accountant = () => {
  const navigate = useNavigate();
  const accountant = useSelector(state => state.authSlice?.accountant)
  const clients = useSelector(state => state.authSlice?.clients)
  const user = useSelector(state => state.authSlice?.user)
  const messages = useSelector(state => state.principalSlice?.messages)
  const [inputText, setInputText] = useState([])
  const [search, setSearch] = useState('')
  const [chosenClient, setChosenClient] = useState(null)

  const initSocket = async()=>{
    const res = await store.dispatch(principalSlice.async$.getAccountantMessages())
    const cookies = new Cookies()
    const token = await cookies.get("token")
    socket.auth = { token }
    socket.on("connect", () => { console.log(`User connected: ${socket.id}`) })
    socket.on("disconnect", () => { console.log(`User disconnected: ${socket.id}`) })
    socket.on('send-message',async(message) => {
      let elem = document.getElementById('messages-container');
      const isAtBottom = elem.scrollTop == (elem.scrollHeight - elem.offsetHeight);
      console.log(elem.scrollTop)
      console.log(elem.scrollHeight)
      await store.dispatch(principalSlice.actions.pushAccountantMessage(message))
      if(isAtBottom) elem.scrollTop = elem.scrollHeight
    })
    await socket.connect()
    sendRead(clients[0]?clients[0].id:null)
    let elem = document.getElementById('messages-container');
    elem.scrollTop = elem.scrollHeight;
    
  }

  const sendMessage = ()=>{
    socket.emit("send-message", {
      content:inputText,
      to: (chosenClient?chosenClient.id:(clients[0]?clients[0].id:null))
    })
  }

  const sendRead = (clientId)=>{
    socket.emit("accountant-read", {
      clientId
    })
  }

  useEffect(()=>{
    initSocket()
  },[])



  return (
    <div className={styles.screen}>
        <AccountantMenu activeScreen={'Messages'} />
        <div className={styles.content}>
          <Header name={user?.name} />
          <div className={styles.accountantMessages}>
            <div className={styles.clientsListWindow}>
            <TextInput 
              customStyle={{
                width:'300px',
                marginLeft:'20px',
                padding:'10px',
                border:'2px solid #446BF7'
              }}
              placeholder={'Search Clients'} 
              type={'email'} 
              onChange={(value)=>{setSearch(value)}} />
              <br/><br/>
                {
                  orderClientsByMessages(messages, filterClients(search, clients)).map((client)=>{
                    return (
                      <div className={styles.client} onClick={(e)=>{ 
                        setChosenClient(client)
                        sendRead(client.id) 
                      }}>
                        <img src={client?.profileImage || defaultProfilePicture} className={styles.clientProfileImage} />
                        <div className={styles.clientText}>
                          <div className={styles.clientName}>{client.name}</div>
                          <div className={styles.clientMessage}>{getLastMessageShortened(messages, client)}</div>
                        </div>
                      </div>
                    )
                  })
                }
            </div>
            <div className={styles.accountantMessagesWindow}>
              <div className={styles.messagesHeader}>
                <img src={accountant?.profileImage || defaultProfilePicture} className={styles.profileImage} />
                {(chosenClient)?'Messages With '+chosenClient?.name:clients[0]?'Messages With '+clients[0].name:'No Clients Yet'}
              </div>
              <div id='messages-container' className={styles.messagesContainer}>
                {
                  messages.filter((message)=>{
                    return message.clientId == (chosenClient?chosenClient.id:(clients[0]?clients[0].id:null))
                  }).map((message)=>{
                    return(
                      <div className={(message.senderId === 0)?styles.sentMessageRow:styles.receivedMessageRow}>
                        <div className={(message.senderId === 0)?styles.sentMessage:styles.receivedMessage}>{message.text}</div>
                        <div className={(message.senderId === 0)?styles.sentTimeRight:styles.sentTimeLeft}>{getSentTimeLocal(message.sent)}</div>
                      </div>
                    )
                  })
                }
              </div>
              <div className={styles.inputContainer}>
                <form className={styles.form} onSubmit={async(event)=>{
                  event.preventDefault()
                  sendMessage()
                  setInputText('')
                  document.getElementById('message-textbox').value = ''
                }}>
                  <input id='message-textbox' placeholder={"Type a message..."} type="text" className={styles.input} value={inputText} onChange={(event)=>{setInputText(event.target.value)}} />
                  <input type='submit' className={styles.sendMessage} value={'Send'} />
                </form>
            </div>
            </div>
          </div>
        </div>
     </div>
  )
}