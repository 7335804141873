
import gettingSetUp from '../images/digital-nomad-2.jpg'
import socialLife from '../images/bar.png'
import exploration from '../images/valencia.png'

import invoicing from '../images/invoicing.JPG'
import bankAccount from '../images/bank-account.JPG'
import nieApplication from '../images/nie-application.jpg'
import tieApplication from '../images/tie-application.jpg'
import friends from '../images/friends.jpg'
import culture from '../images/culture.jpg'
import nationalPark from '../images/national-park.jpg'
import winterActivities from '../images/winter-activities.jpg'
import summerActivities from '../images/summer-activities.jpg'
import restaurant from '../images/restaurant.jpg'
import tour from '../images/tour.jpg'

//Content 
import makingFriendsContent from '../content/makingFriends.js'
import spanishCultureContent from '../content/spanishCulture.js'
import nieContent from '../content/nie.js'
import tieContent from '../content/tie.js'
import SummerContent from '../content/summer.js'
import WinterContent from '../content/winter.js'
import ToursContent from '../content/tours.js'
import RestaurantsContent from '../content/restaurants.js'
import BankContent from '../content/bank.js'

export const contentSections = [
    {id:'getting-set-up',title:'Getting Set Up',background:gettingSetUp,color:'#346beb',emoji:'house'},
    {id:'exploration',title:'Exploration Recommendations',background:exploration,color:'#710ff2', emoji:'mountain'},
    {id:'social-life',title:'Social Life Recommendations',background:socialLife,color:'#d60039',emoji:'clinking-beer-mugs'},
]

export const contentSectionOptions = [
    {id:'all',title:'All',background:gettingSetUp,color:'#346beb',emoji:'sparkles'},
    ...contentSections
]

export const contentItems = [
    {id:'nie',title:'The NIE Application Process',background:nieApplication,color:'#346beb',section:'getting-set-up',content:nieContent},
    {id:'tie',title:'The TIE Application Process',background:tieApplication,color:'#346beb',section:'getting-set-up',content:tieContent},
    {id:'making-friends',title:'Making Friends In Spain',background:friends,color:'#d60039',section:'social-life', content:makingFriendsContent},
    {id:'spanish-culture',title:'Understanding Spanish Culture',background:culture,color:'#d60039',section:'social-life', content: spanishCultureContent},
    {id:'invoicing-rules',title:'Invoicing Rules For Freelancers',background:invoicing,color:'#346beb',section:'getting-set-up'},
    {id:'spanish-bank-account',title:'Creating A Spanish Bank Account',background:bankAccount,color:'#346beb',section:'getting-set-up', content:<BankContent/>},
    {id:'nature-reserves',title:'Nature Reserves By Region',background:nationalPark,color:'#710ff2',section:'exploration'},
    {id:'nature-reserves',title:'Summer Activities By Region',background:summerActivities,color:'#710ff2',section:'exploration', content:<SummerContent />},
    {id:'nature-reserves',title:'Winter Activities By Region',background:winterActivities,color:'#710ff2',section:'exploration', content:<WinterContent />},
    {id:'tours',title:'Tours In Each Region',background:tour,color:'#710ff2',section:'exploration', content:<ToursContent />},
    {id:'restaurants',title:'Restaurant Types In Spain',background:restaurant,color:'#710ff2',section:'exploration', content: <RestaurantsContent />},
]

export const mainCities = [
    'Madrid',
    'Barcelona',
    'Malaga',
    'Alicante',
    'Bilbao',
    'Valencia',
    'Palma de Mallorca',
    'Seville',
    'Zaragoza',
    'Murcia',
    'Las Palmas'
]

export const communities = [
    { code: "01", name: "Andalucía", tours:[
      {
        "title": "Seville City Highlights Tour",
        "details": {
          "duration": "Half-day",
          "description": "Explore the stunning architecture and rich history of Seville. Visit the iconic Seville Cathedral, the Giralda Tower, and the beautiful Alcázar palace. Enjoy a leisurely stroll through the picturesque Santa Cruz neighborhood.",
          "highlights": [
            "Seville Cathedral",
            "Alcázar of Seville",
            "Plaza de España",
            "Metropol Parasol"
          ]
        }
      },
      {
        "title": "Day Trip to Granada and the Alhambra",
        "details": {
          "duration": "Full-day",
          "description": "Visit the breathtaking Alhambra, a UNESCO World Heritage site, and explore the Generalife gardens. Stroll through the charming streets of the Albaicín neighborhood and enjoy panoramic views of the Sierra Nevada mountains.",
          "highlights": [
            "Alhambra Palace",
            "Generalife Gardens",
            "Albaicín neighborhood",
            "Mirador de San Nicolás"
          ]
        }
      },
      {
        "title": "Ronda and White Villages Tour",
        "details": {
          "duration": "Full-day",
          "description": "Discover the stunning town of Ronda, perched on a cliff, and visit the famous Puente Nuevo bridge. Explore the picturesque white villages of Setenil de las Bodegas and Grazalema, known for their charming architecture and breathtaking landscapes.",
          "highlights": [
            "Puente Nuevo",
            "Plaza de Toros",
            "Setenil de las Bodegas",
            "Grazalema"
          ]
        }
      },
      {
        "title": "Cordoba Cultural Experience",
        "details": {
          "duration": "Half-day",
          "description": "Immerse yourself in the rich history of Cordoba. Visit the magnificent Mezquita-Catedral, stroll through the historic Jewish Quarter, and enjoy the beautiful patios that the city is famous for.",
          "highlights": [
            "Mezquita-Catedral",
            "Jewish Quarter",
            "Alcázar de los Reyes Cristianos",
            "Patios of Cordoba"
          ]
        }
      },
      {
        "title": "Malaga Coastal and Culinary Tour",
        "details": {
          "duration": "Full-day",
          "description": "Enjoy a day in Malaga, exploring its beaches and culinary delights. Visit the Picasso Museum, take a stroll along the Malagueta beach, and indulge in traditional tapas at local bars.",
          "highlights": [
            "Picasso Museum",
            "Malagueta Beach",
            "Atarazanas Market",
            "Tapas tasting"
          ]
        }
      },
      {
        "title": "Doñana National Park Wildlife Safari",
        "details": {
          "duration": "Full-day",
          "description": "Experience the unique ecosystems of Doñana National Park. Enjoy a guided safari to spot diverse wildlife, including Iberian lynxes, and explore the park's wetlands and dunes.",
          "highlights": [
            "Wildlife spotting",
            "Wetlands exploration",
            "Dune landscapes",
            "Guided safari experience"
          ]
        }
      }
    ], summer: [
        {
          "title": "Visit the Alhambra",
          "details": "Explore this UNESCO World Heritage site, known for its intricate Islamic architecture and beautiful gardens."
        },
        {
          "title": "Relax on the Beaches",
          "details": "Enjoy the sun and surf at popular spots like Marbella, Tarifa, or Cadiz along the Costa del Sol and Costa de la Luz."
        },
        {
          "title": "Explore Sevilla",
          "details": "Wander through the historic center, visit the Seville Cathedral and the Giralda, and enjoy a flamenco show."
        },
        {
          "title": "Hike in the Sierra Nevada",
          "details": "Experience stunning mountain views and cooler temperatures while hiking in this beautiful range."
        },
        {
          "title": "Discover Ronda",
          "details": "Visit this picturesque town famous for its dramatic cliffs and the stunning Puente Nuevo bridge."
        },
        {
          "title": "Enjoy Tapas",
          "details": "Indulge in the local culinary scene by hopping from bar to bar in cities like Sevilla or Granada."
        },
        {
          "title": "Visit Cordoba",
          "details": "Explore the Mezquita, a mosque-cathedral, and stroll through the charming Jewish Quarter."
        },
        {
          "title": "Attend Festivals",
          "details": "Experience local festivals like Feria de Abril in Sevilla or the August Fair in Málaga for music, dancing, and food."
        },
        {
          "title": "Explore the White Villages",
          "details": "Visit picturesque towns like Mijas, Ronda, and Grazalema, known for their whitewashed buildings and stunning views."
        },
        {
          "title": "Go Wine Tasting",
          "details": "Discover the local wines in regions like Jerez, known for sherry production."
        },
        {
          "title": "Take a Hot Air Balloon Ride",
          "details": "Get a bird's eye view of the stunning landscapes and historical sites."
        },
        {
          "title": "Visit Doñana National Park",
          "details": "Explore this unique biosphere reserve, home to diverse wildlife and beautiful wetlands."
        }
      ], winter: [
        {
          "title": "Ski in the Sierra Nevada",
          "details": "Hit the slopes in Spain's highest mountain range, offering excellent skiing and snowboarding opportunities."
        },
        {
          "title": "Explore Granada's Alhambra",
          "details": "Visit this iconic palace and fortress with fewer crowds, allowing for a more intimate experience of its beauty."
        },
        {
          "title": "Enjoy the Festivities of Christmas",
          "details": "Experience traditional Christmas markets and festivities, particularly in cities like Sevilla and Málaga."
        },
        {
          "title": "Visit the Mesquita-Cathedral in Córdoba",
          "details": "Explore this architectural wonder and enjoy the unique atmosphere during the quieter winter months."
        },
        {
          "title": "Discover the White Villages",
          "details": "Explore the charming whitewashed villages, which are especially picturesque against the winter landscape."
        },
        {
          "title": "Attend the Three Kings Parade",
          "details": "Join the celebrations on January 5th, which include vibrant parades and festivities in cities across Andalucia."
        },
        {
          "title": "Hiking in Mild Weather",
          "details": "Take advantage of the mild winter temperatures to hike in natural parks like Sierra de Grazalema or Los Alcornocales."
        },
        {
          "title": "Visit the Alcázar of Seville",
          "details": "Explore this stunning royal palace, which is beautifully adorned and less crowded in winter."
        },
        {
          "title": "Enjoy Local Cuisine",
          "details": "Savor hearty dishes like cocido and seasonal seafood at cozy restaurants and tapas bars."
        },
        {
          "title": "Explore Málaga's Museums",
          "details": "Visit cultural attractions like the Picasso Museum and the Centre Pompidou, perfect for rainy winter days."
        },
        {
          "title": "Attend Flamenco Shows",
          "details": "Experience the passion of flamenco in intimate venues, especially vibrant in winter months."
        },
        {
          "title": "Take a Day Trip to Jerez",
          "details": "Visit Jerez to enjoy sherry tastings and learn about the region's wine culture in a cozy setting."
        }
      ] },
    { code: "02", name: "Aragón", tours:[
      {
        "title": "Zaragoza City Highlights Tour",
        "details": {
          "duration": "Half-day",
          "description": "Explore the vibrant capital of Aragón, Zaragoza. Visit the stunning Basilica del Pilar, the Aljafería Palace, and stroll along the banks of the Ebro River. Experience the rich history and local culture of this dynamic city.",
          "highlights": [
            "Basilica del Pilar",
            "Aljafería Palace",
            "Plaza del Pilar",
            "Ebro River Walk"
          ]
        }
      },
      {
        "title": "Day Trip to the Pyrenees",
        "details": {
          "duration": "Full-day",
          "description": "Experience the breathtaking beauty of the Pyrenees Mountains. Enjoy hiking in Ordesa National Park, take in stunning views, and visit picturesque villages like Torla and Broto. Ideal for nature lovers and outdoor enthusiasts.",
          "highlights": [
            "Ordesa National Park",
            "Hiking trails",
            "Torla village",
            "Broto waterfalls"
          ]
        }
      },
      {
        "title": "Huesca and the Sierra de Guara",
        "details": {
          "duration": "Full-day",
          "description": "Discover the charming town of Huesca and its rich history. Then venture to the Sierra de Guara for stunning landscapes and opportunities for canyoning or rock climbing. Enjoy local cuisine in a traditional restaurant.",
          "highlights": [
            "Huesca Cathedral",
            "Sierra de Guara Natural Park",
            "Canyoning or rock climbing",
            "Local dining experience"
          ]
        }
      },
      {
        "title": "Mudejar Architecture Tour",
        "details": {
          "duration": "Half-day",
          "description": "Explore the unique Mudejar architecture of Aragón, a UNESCO World Heritage site. Visit the iconic churches and towers in Teruel, including the famous Torre de El Salvador and the stunning cathedral.",
          "highlights": [
            "Teruel Cathedral",
            "Torre de El Salvador",
            "Iglesia de San Pedro",
            "Mudejar towers"
          ]
        }
      },
      {
        "title": "Wine Tasting in Cariñena",
        "details": {
          "duration": "Full-day",
          "description": "Embark on a wine tasting tour in the Cariñena wine region. Visit local vineyards, learn about the winemaking process, and enjoy tasting some of the finest wines paired with traditional Aragónese tapas.",
          "highlights": [
            "Vineyard tours",
            "Wine tasting sessions",
            "Local tapas pairing",
            "Winemaking insights"
          ]
        }
      },
      {
        "title": "Day Trip to Albarracín",
        "details": {
          "duration": "Full-day",
          "description": "Visit the medieval village of Albarracín, known for its narrow winding streets and stunning hilltop views. Explore the ancient walls, the cathedral, and enjoy the picturesque scenery surrounding this historic gem.",
          "highlights": [
            "Albarracín Castle",
            "Cathedral of Albarracín",
            "Medieval streets",
            "Stunning views"
          ]
        }
      }
    ]
    ,summer: [
        {
          "title": "Explore the Pyrenees",
          "details": "Enjoy hiking, mountain biking, and stunning landscapes in the Pyrenees National Park and surrounding areas."
        },
        {
          "title": "Visit Zaragoza",
          "details": "Discover the city's rich history, including the Basilica del Pilar and the Aljafería Palace, and enjoy summer festivals."
        },
        {
          "title": "Go Wine Tasting in Somontano",
          "details": "Tour local wineries and sample delicious wines from this renowned wine-producing region."
        },
        {
          "title": "Discover the Historic Town of Huesca",
          "details": "Stroll through the charming streets, visit the Cathedral, and explore local markets during warm summer evenings."
        },
        {
          "title": "Visit the Monasterio de Piedra",
          "details": "Explore the beautiful park and waterfalls of this historic monastery, ideal for a summer day trip."
        },
        {
          "title": "Attend the Fiestas del Pilar",
          "details": "Join the celebrations in Zaragoza during October, featuring music, dance, and parades, a vibrant summer lead-up."
        },
        {
          "title": "Explore Teruel’s Mudejar Architecture",
          "details": "Visit the UNESCO-listed buildings in Teruel, showcasing unique architectural styles in a picturesque setting."
        },
        {
          "title": "Go Rafting on the Ebro River",
          "details": "Experience thrilling water sports, including rafting and kayaking, in the beautiful Ebro River area."
        },
        {
          "title": "Relax at the Moncayo Natural Park",
          "details": "Hike or picnic in this stunning natural park, known for its diverse flora and fauna, and breathtaking views."
        },
        {
          "title": "Visit Alquézar",
          "details": "Explore this stunning medieval village, with its impressive castle and beautiful surroundings perfect for hiking."
        },
        {
          "title": "Discover the Bardenas Reales",
          "details": "Take a trip to this unique semi-desert landscape, perfect for hiking, cycling, and photography."
        },
        {
          "title": "Experience Local Gastronomy",
          "details": "Savor traditional Aragonese dishes like migas and ternasco at local restaurants, especially in summer festivals."
        }
      ]
      , winter: [
        {
          "title": "Ski in the Pyrenees",
          "details": "Hit the slopes at popular ski resorts like Formigal and Panticosa, offering a range of runs for all skill levels."
        },
        {
          "title": "Explore Zaragoza",
          "details": "Visit the Basilica del Pilar, enjoy the festive lights, and experience the city's vibrant winter atmosphere."
        },
        {
          "title": "Visit the Monasterio de Piedra",
          "details": "Explore this beautiful monastery and its surrounding park, which is stunning in the winter with frozen waterfalls."
        },
        {
          "title": "Discover the Historic Town of Huesca",
          "details": "Wander through this charming town, visit the Cathedral of Huesca, and enjoy local cuisine in cozy restaurants."
        },
        {
          "title": "Attend the Three Kings Parade",
          "details": "Join the celebrations on January 5th in cities like Zaragoza, featuring colorful parades and festivities."
        },
        {
          "title": "Visit the Natural Park of Ordesa and Monte Perdido",
          "details": "Experience breathtaking winter landscapes perfect for hiking or snowshoeing in this stunning national park."
        },
        {
          "title": "Explore the Mudejar Architecture",
          "details": "Visit UNESCO-listed Mudejar buildings in towns like Teruel, showcasing unique architectural styles."
        },
        {
          "title": "Enjoy Local Gastronomy",
          "details": "Savor hearty winter dishes like ternasco (lamb) and local truffles in traditional Aragonese restaurants."
        },
        {
          "title": "Visit the Palacio de la Aljafería",
          "details": "Explore this historic Islamic castle in Zaragoza, which offers a glimpse into the region's rich history."
        },
        {
          "title": "Take a Scenic Drive through the Pyrenees",
          "details": "Enjoy picturesque views of snow-capped mountains and charming villages while exploring the region by car."
        },
        {
          "title": "Relax in Thermal Spas",
          "details": "Visit the thermal baths in the region, such as those in the town of Jaca, for a relaxing winter experience."
        },
        {
          "title": "Experience Winter Festivals",
          "details": "Join local festivities and fairs celebrating winter traditions, especially in smaller towns across the region."
        }
      ]
       },
    { code: "03", name: "Principado de Asturias", tours:[
      {
        "title": "Oviedo City Tour",
        "details": {
          "duration": "Half-day",
          "description": "Discover the historic capital of Asturias, Oviedo. Visit the stunning Cathedral of San Salvador, explore the charming old town, and admire the unique pre-Romanesque architecture of the region.",
          "highlights": [
            "Cathedral of San Salvador",
            "Historic old town",
            "Museo de Bellas Artes de Asturias",
            "Calle Gascona (famous for cider)"
          ]
        }
      },
      {
        "title": "Covadonga and Picos de Europa National Park",
        "details": {
          "duration": "Full-day",
          "description": "Explore the breathtaking landscapes of Picos de Europa National Park. Visit the famous Sanctuary of Covadonga and enjoy hiking trails with stunning views of mountains and lakes.",
          "highlights": [
            "Sanctuary of Covadonga",
            "Lago Enol and Lago Ercina",
            "Hiking in Picos de Europa",
            "Traditional Asturian cuisine"
          ]
        }
      },
      {
        "title": "Gijón Coastal Experience",
        "details": {
          "duration": "Full-day",
          "description": "Experience the coastal charm of Gijón. Enjoy a walk along the beachfront promenade, visit the historic Cimavilla neighborhood, and savor delicious seafood at local restaurants.",
          "highlights": [
            "Cimavilla neighborhood",
            "Playa de San Lorenzo",
            "Gijón's maritime museum",
            "Seafood tasting"
          ]
        }
      },
      {
        "title": "Cider Tour in Asturias",
        "details": {
          "duration": "Half-day",
          "description": "Discover the traditional cider-making process in Asturias. Visit a local sidrería (cider house), learn about the production, and enjoy tastings paired with Asturian tapas.",
          "highlights": [
            "Visit to a cider house",
            "Cider tasting",
            "Asturian tapas",
            "Learn about cider production"
          ]
        }
      },
      {
        "title": "Day Trip to Lastres and Villaviciosa",
        "details": {
          "duration": "Full-day",
          "description": "Visit the picturesque fishing village of Lastres and explore the charming town of Villaviciosa. Enjoy stunning coastal views and sample local products, including the region's famous cider.",
          "highlights": [
            "Lastres harbor",
            "Villaviciosa town center",
            "Coastal views",
            "Cider tasting"
          ]
        }
      },
      {
        "title": "Asturian Hiking Adventure",
        "details": {
          "duration": "Full-day",
          "description": "Embark on a guided hiking tour through the stunning landscapes of Asturias. Choose from various trails suitable for different skill levels, and enjoy the region's natural beauty and diverse flora and fauna.",
          "highlights": [
            "Guided hiking trails",
            "Breathtaking mountain views",
            "Diverse wildlife",
            "Local flora exploration"
          ]
        }
      }
    ]
    ,summer: [
        {
          "title": "Explore the Pyrenees",
          "details": "Enjoy hiking, mountain biking, and stunning landscapes in the Pyrenees National Park and surrounding areas."
        },
        {
          "title": "Visit Zaragoza",
          "details": "Discover the city's rich history, including the Basilica del Pilar and the Aljafería Palace, and enjoy summer festivals."
        },
        {
          "title": "Go Wine Tasting in Somontano",
          "details": "Tour local wineries and sample delicious wines from this renowned wine-producing region."
        },
        {
          "title": "Discover the Historic Town of Huesca",
          "details": "Stroll through the charming streets, visit the Cathedral, and explore local markets during warm summer evenings."
        },
        {
          "title": "Visit the Monasterio de Piedra",
          "details": "Explore the beautiful park and waterfalls of this historic monastery, ideal for a summer day trip."
        },
        {
          "title": "Attend the Fiestas del Pilar",
          "details": "Join the celebrations in Zaragoza during October, featuring music, dance, and parades, a vibrant summer lead-up."
        },
        {
          "title": "Explore Teruel’s Mudejar Architecture",
          "details": "Visit the UNESCO-listed buildings in Teruel, showcasing unique architectural styles in a picturesque setting."
        },
        {
          "title": "Go Rafting on the Ebro River",
          "details": "Experience thrilling water sports, including rafting and kayaking, in the beautiful Ebro River area."
        },
        {
          "title": "Relax at the Moncayo Natural Park",
          "details": "Hike or picnic in this stunning natural park, known for its diverse flora and fauna, and breathtaking views."
        },
        {
          "title": "Visit Alquézar",
          "details": "Explore this stunning medieval village, with its impressive castle and beautiful surroundings perfect for hiking."
        },
        {
          "title": "Discover the Bardenas Reales",
          "details": "Take a trip to this unique semi-desert landscape, perfect for hiking, cycling, and photography."
        },
        {
          "title": "Experience Local Gastronomy",
          "details": "Savor traditional Aragonese dishes like migas and ternasco at local restaurants, especially in summer festivals."
        }
      ]
      , winter: [
        {
          "title": "Ski in the Picos de Europa",
          "details": "Enjoy skiing and snowboarding at the ski resorts in the stunning Picos de Europa National Park."
        },
        {
          "title": "Visit Oviedo",
          "details": "Explore the historic city, visit the Cathedral of San Salvador, and enjoy the festive decorations during the holiday season."
        },
        {
          "title": "Discover Gijón’s Beaches",
          "details": "Stroll along the beautiful beaches, enjoy winter walks, and appreciate the dramatic coastal views."
        },
        {
          "title": "Attend the San Sebastián Festival",
          "details": "Join the vibrant celebrations in January, featuring parades, traditional music, and delicious food."
        },
        {
          "title": "Explore Covadonga",
          "details": "Visit the iconic Covadonga Sanctuary and the stunning lakes of Enol and Ercina, which are especially picturesque in winter."
        },
        {
          "title": "Enjoy Local Cider Houses",
          "details": "Warm up in traditional cider houses (sidrerías) and sample Asturian cider along with hearty local dishes."
        },
        {
          "title": "Hike in the Picos de Europa",
          "details": "Experience beautiful winter hikes with breathtaking views, suitable for various skill levels."
        },
        {
          "title": "Visit the Asturian Villages",
          "details": "Explore charming villages like Cangas de Onís and Lastres, which look enchanting under a blanket of snow."
        },
        {
          "title": "Relax in Thermal Spas",
          "details": "Enjoy the region’s thermal baths, particularly in places like Baños de Montemayor, for a relaxing experience."
        },
        {
          "title": "Discover Local Gastronomy",
          "details": "Savor traditional winter dishes like fabada asturiana (bean stew) and cachopo (breaded meat) at local restaurants."
        },
        {
          "title": "Explore the Jurassic Museum of Asturias",
          "details": "Visit this unique museum in Colunga to learn about the region's prehistoric past and its dinosaur fossils."
        },
        {
          "title": "Attend Cultural Events",
          "details": "Check out local theaters and cultural centers for winter performances, concerts, and art exhibitions."
        }
      ]
      },
    { code: "04", name: "Illes Balears", tours: [
      {
        "title": "Mallorca Island Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the diverse landscapes of Mallorca, from the stunning Tramuntana Mountains to the beautiful beaches. Visit charming villages like Valldemossa and Sóller, and enjoy local cuisine.",
          "highlights": [
            "Tramuntana Mountains",
            "Valldemossa village",
            "Sóller and its vintage train",
            "Local culinary experience"
          ]
        }
      },
      {
        "title": "Ibiza and Formentera Day Trip",
        "details": {
          "duration": "Full-day",
          "description": "Discover the vibrant nightlife of Ibiza and the serene beaches of Formentera. Enjoy a ferry ride, relax on pristine beaches, and explore the stunning coastal landscapes.",
          "highlights": [
            "Ferry ride to Formentera",
            "Playa de Ses Illetes",
            "Ibiza town exploration",
            "Beach clubs and bars"
          ]
        }
      },
      {
        "title": "Menorca Natural Parks Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the natural beauty of Menorca, focusing on its protected parks and stunning coastal areas. Enjoy hiking trails, scenic viewpoints, and relax on quiet beaches.",
          "highlights": [
            "S'Albufera des Grau Natural Park",
            "Cala Mitjana beach",
            "Hiking trails",
            "Wildlife spotting"
          ]
        }
      },
      {
        "title": "Cultural Experience in Palma de Mallorca",
        "details": {
          "duration": "Half-day",
          "description": "Delve into the rich history and culture of Palma. Visit the impressive Cathedral of Santa María, explore the old town, and enjoy tapas at local bars.",
          "highlights": [
            "Cathedral of Santa María",
            "Palma's old town",
            "Castell de Bellver",
            "Tapas tasting"
          ]
        }
      },
      {
        "title": "Snorkeling Adventure in Mallorca",
        "details": {
          "duration": "Full-day",
          "description": "Experience the underwater beauty of Mallorca with a guided snorkeling tour. Explore crystal-clear waters, vibrant marine life, and hidden coves.",
          "highlights": [
            "Guided snorkeling in pristine waters",
            "Visit to hidden coves",
            "Marine life exploration",
            "Snorkeling gear provided"
          ]
        }
      },
      {
        "title": "Wine and Olive Oil Tour in Mallorca",
        "details": {
          "duration": "Half-day",
          "description": "Taste the best of Mallorca’s wines and olive oils on this immersive tour. Visit local wineries and olive oil farms, learn about the production processes, and enjoy tastings.",
          "highlights": [
            "Local winery visit",
            "Olive oil tasting",
            "Learn about wine production",
            "Gourmet snacks included"
          ]
        }
      }
    ]
    ,summer: [
        {
          "title": "Relax on the Beaches",
          "details": "Enjoy the stunning beaches of Mallorca, Menorca, and Ibiza, such as Cala Agulla and Playa de Ses Illetes."
        },
        {
          "title": "Explore Palma",
          "details": "Visit the iconic Palma Cathedral (La Seu), stroll through the vibrant streets, and enjoy outdoor cafes."
        },
        {
          "title": "Go Snorkeling or Diving",
          "details": "Discover the underwater beauty of the Balearic Islands in crystal-clear waters, especially around Cabrera National Park."
        },
        {
          "title": "Attend Music Festivals",
          "details": "Experience world-renowned music festivals and nightlife, especially in Ibiza, known for its vibrant club scene."
        },
        {
          "title": "Hike in the Tramuntana Mountains",
          "details": "Explore the scenic hiking trails, offering breathtaking views and opportunities to discover hidden villages."
        },
        {
          "title": "Visit Menorca’s Prehistoric Sites",
          "details": "Explore the Talayotic sites and ancient stone structures, which are especially beautiful in the summer light."
        },
        {
          "title": "Take a Boat Tour",
          "details": "Enjoy a day out on the water with boat tours to nearby coves, islands, and stunning coastal landscapes."
        },
        {
          "title": "Discover Formentera",
          "details": "Take a ferry to this tranquil island known for its pristine beaches, ideal for a relaxing day trip."
        },
        {
          "title": "Indulge in Local Cuisine",
          "details": "Savor traditional dishes like paella, sobrasada, and fresh seafood at local restaurants and beachside chiringuitos."
        },
        {
          "title": "Explore Ibiza Town",
          "details": "Visit the historic Dalt Vila, enjoy its shops, restaurants, and breathtaking views of the island from the old town."
        },
        {
          "title": "Participate in Water Sports",
          "details": "Try activities like paddleboarding, kayaking, and jet skiing available at many beaches throughout the islands."
        },
        {
          "title": "Attend Local Festivals",
          "details": "Join summer festivities, including traditional celebrations, music events, and local fairs across the islands."
        }
      ]
       , winter: [
        {
          "title": "Explore Palma",
          "details": "Visit the stunning Palma Cathedral (La Seu), stroll through the historic old town, and enjoy the festive decorations."
        },
        {
          "title": "Visit the Caves of Drach",
          "details": "Discover these famous caves on Mallorca, featuring impressive stalactites and an underground lake, less crowded in winter."
        },
        {
          "title": "Hike in the Tramuntana Mountains",
          "details": "Enjoy scenic winter hikes with mild temperatures and breathtaking views along well-marked trails."
        },
        {
          "title": "Relax on the Beaches",
          "details": "Experience the tranquility of the Balearic beaches, perfect for peaceful walks and stunning winter sunsets."
        },
        {
          "title": "Discover Menorca's Prehistoric Sites",
          "details": "Explore the ancient Talayotic sites, including stone monuments and burial chambers, with fewer tourists around."
        },
        {
          "title": "Enjoy Local Cuisine",
          "details": "Savor hearty dishes like sobrasada and calçots at local restaurants, experiencing authentic Balearic flavors."
        },
        {
          "title": "Visit Formentera",
          "details": "Take a ferry to this serene island and enjoy its stunning beaches and quiet atmosphere during the off-season."
        },
        {
          "title": "Attend Winter Festivals",
          "details": "Experience local festivities such as Sant Antoni celebrations in January, featuring parades, bonfires, and traditional music."
        },
        {
          "title": "Explore Ibiza’s Villages",
          "details": "Discover the charm of Ibiza's quaint villages like Santa Gertrudis, known for its artsy vibe and local markets."
        },
        {
          "title": "Visit the Ibiza Cathedral",
          "details": "Explore the impressive cathedral in Dalt Vila, offering beautiful views of the island and harbor."
        },
        {
          "title": "Relax in Spas and Wellness Centers",
          "details": "Pamper yourself in one of the Balearic Islands’ many wellness retreats, ideal for a winter escape."
        },
        {
          "title": "Tour Vineyards",
          "details": "Visit local vineyards to sample Balearic wines and learn about the unique wine-making process in the region."
        }
      ]
       },
    { code: "05", name: "Canarias", tours:[
      {
        "title": "Teide National Park Tour (Tenerife)",
        "details": {
          "duration": "Full-day",
          "description": "Explore the stunning landscapes of Teide National Park, home to Spain's highest peak, Mount Teide. Enjoy scenic drives, hiking trails, and breathtaking views of the unique volcanic terrain.",
          "highlights": [
            "Mount Teide",
            "Roques de Garcia",
            "Cable car ride",
            "Stargazing opportunities"
          ]
        }
      },
      {
        "title": "La Palma Island Exploration",
        "details": {
          "duration": "Full-day",
          "description": "Discover the lush landscapes of La Palma, known as 'La Isla Bonita.' Visit the picturesque town of Santa Cruz, hike through beautiful trails, and enjoy local cuisine.",
          "highlights": [
            "Santa Cruz de La Palma",
            "Los Tilos Forest",
            "Caldera de Taburiente National Park",
            "Local culinary tasting"
          ]
        }
      },
      {
        "title": "Gran Canaria Sightseeing Tour",
        "details": {
          "duration": "Full-day",
          "description": "Experience the diverse landscapes of Gran Canaria. Visit the stunning dunes of Maspalomas, the charming village of Teror, and the picturesque mountains of the interior.",
          "highlights": [
            "Maspalomas Dunes",
            "Teror village",
            "Roque Nublo",
            "Traditional Canarian lunch"
          ]
        }
      },
      {
        "title": "Fuerteventura Beach Hopping",
        "details": {
          "duration": "Full-day",
          "description": "Explore the stunning beaches of Fuerteventura. Visit some of the island’s best spots like Playa de Sotavento and El Cotillo, and enjoy time for swimming, sunbathing, and water sports.",
          "highlights": [
            "Playa de Sotavento",
            "El Cotillo",
            "Water sports opportunities",
            "Beach relaxation"
          ]
        }
      },
      {
        "title": "Lanzarote Volcanic Landscapes Tour",
        "details": {
          "duration": "Full-day",
          "description": "Discover the unique volcanic landscapes of Lanzarote, including Timanfaya National Park. Explore the impressive volcanic craters and learn about the island's geology and wine production.",
          "highlights": [
            "Timanfaya National Park",
            "Volcanic craters",
            "La Geria wine region",
            "Wine tasting"
          ]
        }
      },
      {
        "title": "Whale Watching Tour (Tenerife)",
        "details": {
          "duration": "Half-day",
          "description": "Embark on a thrilling whale-watching adventure off the coast of Tenerife. Spot dolphins and various whale species in their natural habitat while enjoying the stunning ocean views.",
          "highlights": [
            "Dolphin and whale sightings",
            "Experienced guides",
            "Beautiful coastal views",
            "Snorkeling opportunity"
          ]
        }
      }
    ]
    ,summer: [
        {
          "title": "Relax on the Beaches",
          "details": "Enjoy the beautiful beaches of the Canary Islands, such as Playa de las Américas in Tenerife and Playa de Mogán in Gran Canaria."
        },
        {
          "title": "Hike in Teide National Park",
          "details": "Explore the stunning landscapes and volcanic terrain, including a hike to the summit of Mount Teide, Spain's highest peak."
        },
        {
          "title": "Go Whale Watching",
          "details": "Take a boat tour to see dolphins and whales in their natural habitat, particularly around Tenerife and La Gomera."
        },
        {
          "title": "Explore La Palma's Natural Parks",
          "details": "Discover the lush forests and dramatic landscapes of La Palma, known for its hiking trails and stunning viewpoints."
        },
        {
          "title": "Visit the Timanfaya National Park",
          "details": "Experience the unique volcanic landscapes of Lanzarote, including guided tours and demonstrations of geothermal activity."
        },
        {
          "title": "Enjoy Water Sports",
          "details": "Try activities like surfing, windsurfing, and kitesurfing, especially popular in Fuerteventura and Lanzarote."
        },
        {
          "title": "Attend Local Festivals",
          "details": "Join in the vibrant summer festivals, including traditional music, dance, and food events across the islands."
        },
        {
          "title": "Explore the Cueva de los Verdes",
          "details": "Visit this fascinating volcanic cave in Lanzarote, known for its stunning rock formations and underground concert hall."
        },
        {
          "title": "Visit the Historic Towns",
          "details": "Explore charming towns like La Orotava in Tenerife and Betancuria in Fuerteventura, rich in culture and history."
        },
        {
          "title": "Discover the Local Cuisine",
          "details": "Savor traditional dishes like papas arrugadas (wrinkled potatoes) and mojo sauce at local restaurants."
        },
        {
          "title": "Take a Ferry to Nearby Islands",
          "details": "Enjoy day trips to neighboring islands like La Gomera and El Hierro, each offering unique landscapes and experiences."
        },
        {
          "title": "Experience Stargazing",
          "details": "Take advantage of the clear summer nights in La Palma, one of the best places in the world for stargazing."
        }
      ]
      , winter: [
        {
          "title": "Explore Teide National Park",
          "details": "Visit Spain's highest peak, Mount Teide, and enjoy hiking trails amidst stunning volcanic landscapes, often with fewer crowds."
        },
        {
          "title": "Relax on the Beaches",
          "details": "Enjoy mild winter temperatures on beautiful beaches like Playa de las Teresitas in Tenerife and Playa de Amadores in Gran Canaria."
        },
        {
          "title": "Attend Carnival Celebrations",
          "details": "Join in the vibrant Carnival festivities, especially in Santa Cruz de Tenerife and Las Palmas, known for their colorful parades and parties."
        },
        {
          "title": "Go Whale Watching",
          "details": "Take a boat trip to see migrating whales and dolphins, particularly common in the waters between Tenerife and La Gomera."
        },
        {
          "title": "Discover the Caves of Lanzarote",
          "details": "Explore the unique volcanic caves, such as Cueva de los Verdes and Jameos del Agua, which are fascinating to visit in cooler weather."
        },
        {
          "title": "Hike in La Palma",
          "details": "Enjoy winter hiking in La Palma’s lush landscapes, exploring trails through the beautiful Caldera de Taburiente National Park."
        },
        {
          "title": "Visit Historic Towns",
          "details": "Discover charming towns like La Orotava in Tenerife and Betancuria in Fuerteventura, which are perfect for leisurely winter strolls."
        },
        {
          "title": "Experience Local Cuisine",
          "details": "Indulge in hearty Canarian dishes such as caldo de pescado (fish stew) and traditional tapas at local restaurants."
        },
        {
          "title": "Enjoy Spa Treatments",
          "details": "Relax in one of the islands' many spas, offering wellness treatments and thermal baths, ideal for winter relaxation."
        },
        {
          "title": "Take a Ferry to Nearby Islands",
          "details": "Visit nearby islands like La Gomera and El Hierro for unique landscapes and hiking opportunities during the quieter winter months."
        },
        {
          "title": "Explore the Natural Parks",
          "details": "Visit natural parks such as Garajonay National Park on La Gomera, known for its ancient laurel forests and mild winter climate."
        },
        {
          "title": "Go Stargazing",
          "details": "Take advantage of the clear winter skies, especially in La Palma, which is renowned for its astronomical observatories."
        }
      ]
       },
    { code: "06", name: "Cantabria",tours: [
      {
        "title": "Santander City Tour",
        "details": {
          "duration": "Half-day",
          "description": "Explore the elegant city of Santander, the capital of Cantabria. Visit the stunning Palacio de la Magdalena, stroll along the beautiful beaches, and discover local shops and cafes.",
          "highlights": [
            "Palacio de la Magdalena",
            "Playa del Sardinero",
            "Centro Botín",
            "Local gastronomy"
          ]
        }
      },
      {
        "title": "Picos de Europa National Park Adventure",
        "details": {
          "duration": "Full-day",
          "description": "Discover the breathtaking landscapes of Picos de Europa National Park. Enjoy hiking trails with stunning views, visit charming villages like Potes, and sample local cheeses and cider.",
          "highlights": [
            "Hiking trails",
            "Potes village",
            "Fuente Dé cable car",
            "Local cheese tasting"
          ]
        }
      },
      {
        "title": "Altamira Caves and Museum Tour",
        "details": {
          "duration": "Half-day",
          "description": "Visit the famous Altamira Caves, known for their prehistoric cave paintings. Explore the museum to learn about the history of the cave and the significance of its artwork.",
          "highlights": [
            "Altamira Caves",
            "Museum of Altamira",
            "Prehistoric art history",
            "Guided tours available"
          ]
        }
      },
      {
        "title": "Comillas and San Vicente de la Barquera Day Trip",
        "details": {
          "duration": "Full-day",
          "description": "Explore the picturesque towns of Comillas and San Vicente de la Barquera. Visit the stunning Gaudí-designed El Capricho and enjoy coastal views and local seafood.",
          "highlights": [
            "El Capricho in Comillas",
            "San Vicente de la Barquera",
            "Beaches and coastal views",
            "Seafood tasting"
          ]
        }
      },
      {
        "title": "Cueva El Soplao Tour",
        "details": {
          "duration": "Half-day",
          "description": "Discover the spectacular El Soplao Cave, known for its stunning geological formations. Take a guided tour through the cave to see impressive stalactites and stalagmites.",
          "highlights": [
            "El Soplao Cave",
            "Guided cave tour",
            "Stalactite and stalagmite formations",
            "Unique geological features"
          ]
        }
      },
      {
        "title": "Liébana Valley and Cheese Tasting",
        "details": {
          "duration": "Full-day",
          "description": "Explore the stunning Liébana Valley, famous for its landscapes and local cheeses. Visit cheese producers, sample artisanal cheeses, and enjoy the breathtaking scenery.",
          "highlights": [
            "Liébana Valley views",
            "Cheese producer visits",
            "Cheese tasting",
            "Local culinary experience"
          ]
        }
      }
    ]
    ,summer: [
        {
          "title": "Visit Santander",
          "details": "Explore the capital city, enjoy its beautiful beaches like Playa del Sardinero, and visit the Palacio de la Magdalena."
        },
        {
          "title": "Explore Picos de Europa National Park",
          "details": "Experience stunning hiking trails, breathtaking views, and outdoor activities like rock climbing and canyoning."
        },
        {
          "title": "Relax at the Beaches",
          "details": "Enjoy sunbathing and water sports at popular beaches such as Playa de Comillas and Playa de Oyambre."
        },
        {
          "title": "Discover the Altamira Cave",
          "details": "Visit the famous cave, known for its prehistoric paintings, and explore the nearby Altamira Museum."
        },
        {
          "title": "Stroll through Santillana del Mar",
          "details": "Wander the cobblestone streets of this picturesque medieval village, known for its charming architecture and historic sites."
        },
        {
          "title": "Take a Boat Tour of the Cantabrian Coast",
          "details": "Explore the rugged coastline and its cliffs, caves, and marine life on a scenic boat tour."
        },
        {
          "title": "Visit the Cabárceno Natural Park",
          "details": "Experience a wildlife park with a variety of animals in semi-captivity, perfect for families and nature lovers."
        },
        {
          "title": "Enjoy Local Cuisine",
          "details": "Savor Cantabrian dishes like sobao pasiego and seafood, especially in coastal towns and local restaurants."
        },
        {
          "title": "Explore Comillas",
          "details": "Visit this beautiful town, famous for its modernist architecture, including the Palacio de Sobrellano and El Capricho."
        },
        {
          "title": "Hike the Senda Costera",
          "details": "Enjoy scenic coastal hikes along the Senda Costera trail, which offers stunning views of the sea and cliffs."
        },
        {
          "title": "Attend Local Festivals",
          "details": "Join in the summer festivities and traditional fairs celebrating Cantabrian culture, music, and food."
        },
        {
          "title": "Visit the Museo de Prehistoria y Arqueología de Cantabria",
          "details": "Learn about the region's rich history and archaeology through fascinating exhibits and artifacts."
        }
      ]
      , winter: [
        {
          "title": "Visit Santander",
          "details": "Explore the capital city, enjoy its cozy cafés, and take a winter stroll along the waterfront and beaches."
        },
        {
          "title": "Explore Picos de Europa National Park",
          "details": "Experience winter hiking or snowshoeing in the stunning landscapes, with fewer crowds and breathtaking views."
        },
        {
          "title": "Discover the Altamira Cave",
          "details": "Visit the famous prehistoric cave and its museum, enjoying a warm indoor experience during the chilly months."
        },
        {
          "title": "Relax in a Spa",
          "details": "Unwind at one of the region's thermal spas, such as the Termas de Loredo, for a rejuvenating winter experience."
        },
        {
          "title": "Visit the Cabárceno Natural Park",
          "details": "See the wildlife in a different season, as many animals are more active during winter months in this unique park."
        },
        {
          "title": "Stroll through Santillana del Mar",
          "details": "Wander the picturesque streets of this medieval village, which looks enchanting in the winter light."
        },
        {
          "title": "Enjoy Local Cuisine",
          "details": "Savor hearty Cantabrian dishes like cocido montañés (mountain stew) and regional cheeses in cozy restaurants."
        },
        {
          "title": "Attend Winter Festivals",
          "details": "Join in local winter festivities and celebrations, including Christmas markets and traditional events in towns."
        },
        {
          "title": "Visit the Museo de Prehistoria y Arqueología de Cantabria",
          "details": "Learn about the region's history through fascinating indoor exhibits, perfect for winter exploration."
        },
        {
          "title": "Take a Winter Coastal Walk",
          "details": "Enjoy refreshing coastal walks along the beaches, taking in the dramatic winter scenery and fresh sea air."
        },
        {
          "title": "Explore the Comillas",
          "details": "Visit this charming town and admire its modernist architecture while enjoying the peaceful winter atmosphere."
        },
        {
          "title": "Experience the Festive Season",
          "details": "Enjoy holiday decorations and events in local towns, with a festive atmosphere throughout the region."
        }
      ]
       },
    { code: "07", name: "Castilla y León", tours:[[
      {
        "title": "Segovia and Its Aqueduct Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the historic city of Segovia, famous for its impressive Roman aqueduct. Visit the stunning Alcázar, the Gothic cathedral, and enjoy a stroll through the charming old town.",
          "highlights": [
            "Roman Aqueduct",
            "Alcázar of Segovia",
            "Segovia Cathedral",
            "Local cochinillo (suckling pig) tasting"
          ]
        }
      },
      {
        "title": "Salamanca Cultural Experience",
        "details": {
          "duration": "Full-day",
          "description": "Discover the vibrant university city of Salamanca, a UNESCO World Heritage site. Visit the historic university, stunning Plaza Mayor, and explore the unique architecture of the old town.",
          "highlights": [
            "University of Salamanca",
            "Plaza Mayor",
            "Casa de las Conchas",
            "Local tapas tasting"
          ]
        }
      },
      {
        "title": "Day Trip to Valladolid",
        "details": {
          "duration": "Full-day",
          "description": "Visit Valladolid, known for its rich history and vibrant culture. Explore the historic center, visit the National Sculpture Museum, and enjoy the local wine and gastronomy.",
          "highlights": [
            "Plaza Mayor",
            "National Sculpture Museum",
            "Catedral de Valladolid",
            "Wine tasting"
          ]
        }
      },
      {
        "title": "Burgos and Its Cathedral Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore Burgos, famous for its stunning Gothic cathedral, a UNESCO World Heritage site. Stroll through the old town, visit the Monastery of Las Huelgas, and enjoy local dishes.",
          "highlights": [
            "Burgos Cathedral",
            "Monastery of Las Huelgas",
            "Historic old town",
            "Local morcilla (blood sausage) tasting"
          ]
        }
      },
      {
        "title": "Ronda de la Laguna and Nature Walk",
        "details": {
          "duration": "Half-day",
          "description": "Experience the natural beauty of the Ronda de la Laguna area. Enjoy scenic walks through the countryside, spot local wildlife, and learn about the region's flora and fauna.",
          "highlights": [
            "Guided nature walk",
            "Scenic views",
            "Wildlife spotting",
            "Picnic in nature"
          ]
        }
      },
      {
        "title": "Wine Tasting in Ribera del Duero",
        "details": {
          "duration": "Full-day",
          "description": "Discover the renowned Ribera del Duero wine region. Visit local wineries, learn about the winemaking process, and enjoy tastings of exquisite local wines paired with traditional cuisine.",
          "highlights": [
            "Winery visits",
            "Wine tasting sessions",
            "Gourmet dining experience",
            "Learn about local winemaking"
          ]
        }
      }
    ]
    ],summer: [
        {
          "title": "Explore Salamanca",
          "details": "Visit the historic university city, known for its stunning architecture, vibrant atmosphere, and the famous Plaza Mayor."
        },
        {
          "title": "Visit the Gothic Cathedral of Burgos",
          "details": "Admire the impressive architecture of the Burgos Cathedral, a UNESCO World Heritage site, and explore the surrounding old town."
        },
        {
          "title": "Discover the Alcázar of Segovia",
          "details": "Explore this fairy-tale castle, which offers panoramic views of the city and the nearby aqueduct, perfect for summer photography."
        },
        {
          "title": "Hike in the Sierra de Guadarrama",
          "details": "Enjoy hiking in this beautiful national park, with trails offering stunning views, fresh air, and diverse wildlife."
        },
        {
          "title": "Experience the Wine Routes",
          "details": "Visit the Ribera del Duero and Rueda wine regions for vineyard tours and tastings of excellent local wines."
        },
        {
          "title": "Attend Local Festivals",
          "details": "Join in vibrant summer festivals, including music, dance, and traditional celebrations in various towns and cities."
        },
        {
          "title": "Explore León’s Historic Center",
          "details": "Wander the streets of León, visiting the stunning Gothic Cathedral and enjoying the lively tapas scene."
        },
        {
          "title": "Visit the Las Médulas",
          "details": "Discover this UNESCO World Heritage site, an ancient Roman gold mine with breathtaking landscapes and hiking trails."
        },
        {
          "title": "Relax at Lake Sanabria",
          "details": "Enjoy water activities and relaxing beach days at Spain's largest glacial lake, surrounded by beautiful natural scenery."
        },
        {
          "title": "Explore the Historic Town of Ávila",
          "details": "Walk along the well-preserved medieval walls and explore the charming old town, famous for its many churches."
        },
        {
          "title": "Visit the Roman City of Segovia",
          "details": "Marvel at the impressive Roman aqueduct, one of the best-preserved in the world, and enjoy Segovia’s unique cuisine."
        },
        {
          "title": "Discover the Caves of Altamira",
          "details": "Explore these famous prehistoric caves and their stunning rock art, located near the town of Santillana del Mar."
        }
      ]
      , winter: [
        {
          "title": "Visit Salamanca",
          "details": "Explore the historic university city, with its beautiful plazas and architecture, and enjoy the festive holiday decorations."
        },
        {
          "title": "Discover the Alcázar of Segovia",
          "details": "Tour this iconic castle, which looks stunning in winter, and enjoy views of the snow-covered city and surrounding landscape."
        },
        {
          "title": "Ski in the Sierra de Béjar",
          "details": "Hit the slopes at La Covatilla ski resort, perfect for skiing and snowboarding enthusiasts looking for winter sports."
        },
        {
          "title": "Explore León’s Historic Center",
          "details": "Wander through León’s charming streets, visit the Gothic Cathedral, and enjoy the warm atmosphere of local cafés."
        },
        {
          "title": "Attend Christmas Markets",
          "details": "Enjoy the festive atmosphere at Christmas markets in various cities, featuring local crafts, foods, and holiday cheer."
        },
        {
          "title": "Visit the Cathedral of Burgos",
          "details": "Marvel at the stunning Gothic architecture of the Burgos Cathedral, and explore the cozy cafés nearby during winter."
        },
        {
          "title": "Discover the Roman City of Astorga",
          "details": "Explore the Roman ruins and the stunning Gaudí-designed palace, enjoying the quiet beauty of this historic town in winter."
        },
        {
          "title": "Relax in Thermal Baths",
          "details": "Unwind at the natural thermal baths in places like Baños de Molgas, enjoying a warm soak amid winter scenery."
        },
        {
          "title": "Explore the Caves of Altamira",
          "details": "Visit the famous prehistoric caves and learn about their significance, which can be especially intriguing during the winter months."
        },
        {
          "title": "Attend Local Festivals",
          "details": "Experience traditional winter festivities and celebrations, showcasing local culture and cuisine in various towns."
        },
        {
          "title": "Visit the Las Médulas",
          "details": "Discover this UNESCO World Heritage site, which is stunning in winter light and offers great hiking opportunities."
        },
        {
          "title": "Taste Local Winter Cuisine",
          "details": "Enjoy hearty dishes like cocido (stew) and other regional specialties at cozy restaurants throughout the region."
        }
      ]
       },
    { code: "08", name: "Castilla - La Mancha", tours: [
      {
        "title": "Toledo Historical Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the historic city of Toledo, a UNESCO World Heritage site. Visit the stunning Toledo Cathedral, the Alcázar, and enjoy the diverse architectural styles reflecting its rich history.",
          "highlights": [
            "Toledo Cathedral",
            "Alcázar of Toledo",
            "Jewish Quarter",
            "El Greco Museum"
          ]
        }
      },
      {
        "title": "Windmills and Villages of Consuegra",
        "details": {
          "duration": "Half-day",
          "description": "Visit the iconic windmills of Consuegra, made famous by Cervantes' 'Don Quixote.' Explore the charming village and enjoy panoramic views of the surrounding countryside.",
          "highlights": [
            "Consuegra Windmills",
            "Castle of Consuegra",
            "Local artisan shops",
            "Stunning landscape views"
          ]
        }
      },
      {
        "title": "Alcalá de Henares and Cervantes Tour",
        "details": {
          "duration": "Full-day",
          "description": "Discover the birthplace of Miguel de Cervantes in Alcalá de Henares. Visit the Cervantes Birthplace Museum, the historic university, and enjoy the local tapas scene.",
          "highlights": [
            "Cervantes Birthplace Museum",
            "University of Alcalá",
            "Plaza de Cervantes",
            "Local tapas tasting"
          ]
        }
      },
      {
        "title": "Cuenca and Its Hanging Houses",
        "details": {
          "duration": "Full-day",
          "description": "Explore the picturesque city of Cuenca, known for its unique hanging houses. Visit the Cathedral of Cuenca, stroll through the old town, and enjoy stunning views from the Huécar River gorge.",
          "highlights": [
            "Hanging Houses of Cuenca",
            "Cuenca Cathedral",
            "Historic old town",
            "Mirador de la Ciudad Encantada"
          ]
        }
      },
      {
        "title": "Wine Tasting in La Mancha",
        "details": {
          "duration": "Full-day",
          "description": "Experience the renowned La Mancha wine region. Visit local wineries, learn about the winemaking process, and enjoy tastings of exquisite wines paired with traditional dishes.",
          "highlights": [
            "Winery visits",
            "Wine tasting sessions",
            "Gourmet dining experience",
            "Learn about local grape varieties"
          ]
        }
      },
      {
        "title": "Natural Park of Cabañeros Day Trip",
        "details": {
          "duration": "Full-day",
          "description": "Discover the natural beauty of Cabañeros National Park. Enjoy guided hikes through diverse landscapes, spot local wildlife, and immerse yourself in the tranquility of nature.",
          "highlights": [
            "Guided nature walks",
            "Wildlife spotting",
            "Scenic landscapes",
            "Picnic in nature"
          ]
        }
      }
    ]
    ,summer: [
        {
          "title": "Explore Toledo",
          "details": "Wander through the historic streets of Toledo, visiting landmarks like the Toledo Cathedral and the Alcázar, and enjoy outdoor dining in the warm summer evenings."
        },
        {
          "title": "Visit Cuenca",
          "details": "Discover the unique hanging houses and explore the beautiful old town, perfect for leisurely summer strolls and photography."
        },
        {
          "title": "Tour the Windmills of Consuegra",
          "details": "Visit the iconic windmills that inspired Don Quixote, and enjoy panoramic views of the La Mancha landscape."
        },
        {
          "title": "Enjoy Wine Tasting",
          "details": "Take a tour of local vineyards in La Mancha, sampling excellent wines and experiencing the wine-making process."
        },
        {
          "title": "Relax at Tablas de Daimiel National Park",
          "details": "Explore this unique wetland park, ideal for birdwatching, hiking, and enjoying the summer flora and fauna."
        },
        {
          "title": "Visit the Castillo de Belmonte",
          "details": "Explore this well-preserved medieval castle, which often hosts summer events and reenactments."
        },
        {
          "title": "Attend Local Festivals",
          "details": "Join in summer festivities, including music festivals and traditional fairs that celebrate local culture and cuisine."
        },
        {
          "title": "Discover the Don Quixote Route",
          "details": "Follow the scenic route inspired by Cervantes' novel, visiting locations related to the famous story across the region."
        },
        {
          "title": "Explore the Historic City of Guadalajara",
          "details": "Visit the beautiful architecture and enjoy outdoor events in this charming city during the summer months."
        },
        {
          "title": "Enjoy Outdoor Activities in Sierra de Cuenca",
          "details": "Take advantage of the warmer weather for hiking, rock climbing, and nature walks in the picturesque Sierra de Cuenca."
        },
        {
          "title": "Relax in the Natural Pools of Cuenca",
          "details": "Enjoy a refreshing swim in the natural pools, surrounded by stunning scenery—perfect for a hot summer day."
        },
        {
          "title": "Visit the Museo del Greco in Toledo",
          "details": "Explore the museum dedicated to the famous painter, appreciating art and culture in a cool indoor setting."
        }
      ]
      , winter: [
        {
          "title": "Visit Toledo",
          "details": "Explore the historic city, enjoying its beautiful architecture and festive decorations during the winter season."
        },
        {
          "title": "Discover Cuenca",
          "details": "Wander through the enchanting old town, admire the hanging houses, and enjoy the cozy cafés and restaurants."
        },
        {
          "title": "Explore the Alcázar of Segovia",
          "details": "Visit this iconic castle, which looks stunning in winter, with opportunities for guided tours and beautiful views."
        },
        {
          "title": "Attend Christmas Markets",
          "details": "Experience local holiday markets in various towns, offering crafts, foods, and a festive atmosphere."
        },
        {
          "title": "Tour the Windmills of Consuegra",
          "details": "Visit the famous windmills, enjoy the winter scenery, and take memorable photos against the stunning backdrop."
        },
        {
          "title": "Warm Up with Local Cuisine",
          "details": "Savor hearty dishes like cocido (stew) and other regional specialties in cozy restaurants across the region."
        },
        {
          "title": "Visit the Castillo de Belmonte",
          "details": "Explore this impressive medieval castle, which often hosts winter events and offers guided tours."
        },
        {
          "title": "Relax in Thermal Baths",
          "details": "Unwind in natural thermal baths in places like Baños de Molgas, perfect for a warm escape from the winter chill."
        },
        {
          "title": "Enjoy Winter Sports in Sierra de Cuenca",
          "details": "Take advantage of the winter conditions for activities like snowshoeing or hiking in the beautiful Sierra de Cuenca."
        },
        {
          "title": "Explore the Caves of La Cueva de los Casares",
          "details": "Visit this intriguing cave system, perfect for exploring the natural beauty and geological formations in winter."
        },
        {
          "title": "Attend Local Festivals",
          "details": "Join in the festive atmosphere of traditional winter celebrations, including processions and local fairs."
        },
        {
          "title": "Visit the Museo del Greco in Toledo",
          "details": "Explore the art and history dedicated to the famous painter, a perfect indoor activity during colder days."
        }
      ]
       },
    { code: "09", name: "Cataluña", tours: [
      {
        "title": "Barcelona City Highlights Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the vibrant city of Barcelona. Visit iconic landmarks such as La Sagrada Familia, Park Güell, and the Gothic Quarter. Enjoy local cuisine at a traditional tapas bar.",
          "highlights": [
            "La Sagrada Familia",
            "Park Güell",
            "Gothic Quarter",
            "Tapas tasting"
          ]
        }
      },
      {
        "title": "Montserrat Monastery Day Trip",
        "details": {
          "duration": "Full-day",
          "description": "Visit the stunning Montserrat Monastery, set high in the mountains. Enjoy breathtaking views, explore the basilica, and hike the scenic trails surrounding the monastery.",
          "highlights": [
            "Montserrat Monastery",
            "Basilica and Black Madonna",
            "Hiking trails",
            "Panoramic mountain views"
          ]
        }
      },
      {
        "title": "Girona and Costa Brava Tour",
        "details": {
          "duration": "Full-day",
          "description": "Discover the medieval city of Girona and the stunning landscapes of Costa Brava. Explore the historic Jewish Quarter, visit the colorful houses along the Onyar River, and relax on beautiful beaches.",
          "highlights": [
            "Girona's Jewish Quarter",
            "Onyar River views",
            "Costa Brava beaches",
            "Local seafood dining"
          ]
        }
      },
      {
        "title": "Tarragona Roman Heritage Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the ancient city of Tarragona, known for its well-preserved Roman ruins. Visit the impressive amphitheater, the Roman circus, and the historic old town.",
          "highlights": [
            "Tarragona Amphitheater",
            "Roman Circus",
            "Tarragona Cathedral",
            "Historic old town"
          ]
        }
      },
      {
        "title": "Wine and Cava Tour in Penedès",
        "details": {
          "duration": "Full-day",
          "description": "Experience the renowned wine and cava region of Penedès. Visit local wineries, learn about the production process, and enjoy tastings of exquisite wines and cavas paired with local delicacies.",
          "highlights": [
            "Winery visits",
            "Cava tasting sessions",
            "Gourmet dining experience",
            "Learn about local grape varieties"
          ]
        }
      },
      {
        "title": "Cadaqués and Cap de Creus Natural Park",
        "details": {
          "duration": "Full-day",
          "description": "Visit the charming coastal town of Cadaqués, known for its whitewashed buildings and stunning views. Explore the nearby Cap de Creus Natural Park, famous for its rugged coastline and hiking trails.",
          "highlights": [
            "Cadaqués village",
            "Dali House Museum",
            "Cap de Creus Natural Park",
            "Scenic coastal views"
          ]
        }
      }
    ]
    ,summer: [
        {
          "title": "Explore Barcelona",
          "details": "Visit iconic landmarks such as La Sagrada Familia, Park Güell, and the Gothic Quarter, and enjoy outdoor dining in vibrant plazas."
        },
        {
          "title": "Relax on Costa Brava Beaches",
          "details": "Enjoy the stunning beaches along the Costa Brava, including Cala Montjoi and Platja de Aro, perfect for sunbathing and water sports."
        },
        {
          "title": "Visit Montserrat",
          "details": "Take a day trip to Montserrat to see the famous monastery, hike the surrounding trails, and enjoy breathtaking views of the mountains."
        },
        {
          "title": "Discover Tarragona’s Roman Heritage",
          "details": "Explore the ancient ruins in Tarragona, including the Roman amphitheater and aqueduct, along with beautiful Mediterranean views."
        },
        {
          "title": "Attend the Festa Major de Gràcia",
          "details": "Join in this lively street festival in August, featuring elaborate decorations, concerts, and local food throughout the Gràcia neighborhood."
        },
        {
          "title": "Hike in the Pyrenees",
          "details": "Enjoy hiking and outdoor activities in the Catalan Pyrenees, with trails offering stunning views of nature and wildlife."
        },
        {
          "title": "Explore Girona’s Historic Old Town",
          "details": "Wander the charming streets of Girona, visit the cathedral, and enjoy the colorful houses along the Onyar River."
        },
        {
          "title": "Visit Sitges",
          "details": "Enjoy the vibrant beach town of Sitges, known for its beautiful beaches, lively nightlife, and cultural festivals."
        },
        {
          "title": "Experience Catalan Cuisine",
          "details": "Savor local dishes such as paella, escalivada, and crema catalana at outdoor restaurants and local markets."
        },
        {
          "title": "Take a Wine Tour in Priorat",
          "details": "Explore the renowned wine region of Priorat, visiting vineyards and tasting some of the best wines in Spain."
        },
        {
          "title": "Discover the Dalí Theatre-Museum in Figueres",
          "details": "Visit the museum dedicated to Salvador Dalí, featuring a unique collection of his works in a striking building designed by the artist."
        },
        {
          "title": "Attend Outdoor Concerts and Festivals",
          "details": "Join various summer music festivals and outdoor concerts held throughout Cataluña, celebrating local and international artists."
        }
      ]
      , winter: [
        {
          "title": "Visit Barcelona",
          "details": "Explore the city's famous attractions like La Sagrada Familia and Park Güell with fewer crowds, and enjoy the festive Christmas markets."
        },
        {
          "title": "Ski in the Pyrenees",
          "details": "Hit the slopes at popular ski resorts like La Molina and Baqueira Beret, offering great conditions for skiing and snowboarding."
        },
        {
          "title": "Discover Girona's Festive Atmosphere",
          "details": "Wander through Girona’s old town, adorned with festive lights and decorations, and enjoy the seasonal culinary offerings."
        },
        {
          "title": "Explore Tarragona's Roman Sites",
          "details": "Visit Tarragona’s ancient Roman ruins, such as the amphitheater and the walls, and enjoy the milder winter climate."
        },
        {
          "title": "Experience the Caga Tió Tradition",
          "details": "Join in the fun of the Catalan Christmas tradition, where children hit a wooden log (Caga Tió) to 'make it poop' presents."
        },
        {
          "title": "Attend the Three Kings Parade",
          "details": "Celebrate the arrival of the Three Wise Men on January 5th with parades and festivities held in cities across Cataluña."
        },
        {
          "title": "Visit Montserrat Monastery",
          "details": "Take a trip to the stunning Montserrat Mountain, enjoying peaceful hikes and visiting the famous monastery, especially beautiful in winter."
        },
        {
          "title": "Enjoy Catalan Cuisine",
          "details": "Warm up with traditional winter dishes like escudella i carn d'olla (a hearty stew) in local restaurants."
        },
        {
          "title": "Discover the Dalí Theatre-Museum in Figueres",
          "details": "Explore the surreal works of Salvador Dalí in a unique museum setting, with fewer visitors during the winter months."
        },
        {
          "title": "Relax in Thermal Spas",
          "details": "Unwind in thermal baths in regions like Caldes de Montbui, enjoying a relaxing escape from the winter chill."
        },
        {
          "title": "Experience the Christmas Markets",
          "details": "Visit the charming Christmas markets in Barcelona and other towns, offering local crafts, seasonal treats, and holiday cheer."
        },
        {
          "title": "Attend Local Festivals",
          "details": "Join in various winter festivals celebrating local culture, including music and traditional events across different towns."
        }
      ]
       },
    { code: "10", name: "Comunitat Valenciana", tours:[
      {
        "title": "Valencia City Highlights Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the vibrant city of Valencia. Visit the stunning City of Arts and Sciences, the historic Valencia Cathedral, and enjoy a stroll through the beautiful Turia Gardens.",
          "highlights": [
            "City of Arts and Sciences",
            "Valencia Cathedral",
            "Central Market",
            "Turia Gardens"
          ]
        }
      },
      {
        "title": "Albufera Natural Park and Paella Experience",
        "details": {
          "duration": "Half-day",
          "description": "Discover the beauty of Albufera Natural Park, famous for its rice fields and stunning lagoons. Enjoy a traditional paella cooking experience at a local restaurant.",
          "highlights": [
            "Albufera Natural Park",
            "Boat ride on the lagoon",
            "Paella cooking class",
            "Local gastronomy"
          ]
        }
      },
      {
        "title": "Day Trip to Alicante",
        "details": {
          "duration": "Full-day",
          "description": "Visit the coastal city of Alicante. Explore the historic Santa Bárbara Castle, relax on beautiful beaches, and stroll through the vibrant old town.",
          "highlights": [
            "Santa Bárbara Castle",
            "Explanada de España",
            "Postiguet Beach",
            "Local seafood tasting"
          ]
        }
      },
      {
        "title": "Castellón and Desert de les Palmes",
        "details": {
          "duration": "Full-day",
          "description": "Discover the beautiful province of Castellón. Visit the historic town of Morella, explore the Desert de les Palmes Natural Park, and enjoy local cuisine.",
          "highlights": [
            "Morella Castle",
            "Desert de les Palmes",
            "Historic old town",
            "Local dining experience"
          ]
        }
      },
      {
        "title": "Valencia Wine and Cava Tour",
        "details": {
          "duration": "Full-day",
          "description": "Experience the renowned wine regions of Valencia. Visit local wineries, learn about the winemaking process, and enjoy tastings of exquisite wines and cavas paired with traditional dishes.",
          "highlights": [
            "Winery visits",
            "Wine tasting sessions",
            "Gourmet dining experience",
            "Learn about local grape varieties"
          ]
        }
      },
      {
        "title": "Sagunto and Its Roman History",
        "details": {
          "duration": "Half-day",
          "description": "Explore the ancient city of Sagunto, rich in Roman history. Visit the impressive Roman theater, the castle, and learn about its significance in history.",
          "highlights": [
            "Sagunto Roman Theater",
            "Sagunto Castle",
            "Historic town center",
            "Guided history tour"
          ]
        }
      }
    ]
    ,summer: [
        {
          "title": "Relax on the Beaches of Valencia",
          "details": "Enjoy the sun and sea at popular beaches like La Malvarrosa and Playa de las Arenas, perfect for swimming and water sports."
        },
        {
          "title": "Explore the City of Valencia",
          "details": "Visit iconic landmarks such as the City of Arts and Sciences, Valencia Cathedral, and stroll through the historic center."
        },
        {
          "title": "Attend Las Fallas Festival",
          "details": "Experience the famous Las Fallas festival in March, featuring impressive sculptures, fireworks, and lively street celebrations."
        },
        {
          "title": "Visit Albufera Natural Park",
          "details": "Explore this beautiful wetland area, ideal for birdwatching, hiking, and taking boat rides on the Albufera Lagoon."
        },
        {
          "title": "Taste Authentic Paella",
          "details": "Indulge in the region's famous dish, paella, at local restaurants and beachfront eateries in Valencia and surrounding areas."
        },
        {
          "title": "Explore the Costa Blanca",
          "details": "Discover stunning coastal towns like Benidorm and Altea, known for their beautiful beaches and vibrant nightlife."
        },
        {
          "title": "Hike in the Sierra de Espuña",
          "details": "Enjoy hiking and outdoor activities in the Sierra de Espuña, with beautiful trails and breathtaking views of the mountains."
        },
        {
          "title": "Visit the Historic Town of Xàtiva",
          "details": "Explore the charming old town, featuring a stunning castle, historic architecture, and beautiful gardens."
        },
        {
          "title": "Enjoy Water Sports",
          "details": "Try various water sports such as paddleboarding, jet skiing, and windsurfing at the beaches along the coast."
        },
        {
          "title": "Attend Music Festivals",
          "details": "Join in summer music festivals and concerts held throughout the region, showcasing both local and international artists."
        },
        {
          "title": "Discover the Bioparc Valencia",
          "details": "Visit this unique zoo that emphasizes conservation and offers an immersive experience with animals in their natural habitats."
        },
        {
          "title": "Explore the Caves of San José",
          "details": "Take a guided tour of the stunning caves in La Serranía, known for their impressive rock formations and underground river."
        }
      ]
      , winter: [
        {
          "title": "Visit Valencia's Historic Center",
          "details": "Explore the city's beautiful historic sites, including the Valencia Cathedral and the Central Market, with fewer crowds during winter."
        },
        {
          "title": "Enjoy the Las Fallas Preparations",
          "details": "Experience the buildup to the famous Las Fallas festival in March, with smaller events and displays in December and January."
        },
        {
          "title": "Explore Albufera Natural Park",
          "details": "Visit this stunning wetland area for birdwatching and peaceful walks, enjoying the tranquility of nature in winter."
        },
        {
          "title": "Taste Traditional Winter Cuisine",
          "details": "Indulge in hearty local dishes like olla gitana (a traditional stew) and other seasonal delicacies in cozy restaurants."
        },
        {
          "title": "Attend Christmas Markets",
          "details": "Enjoy the festive atmosphere at Christmas markets in Valencia, offering local crafts, treats, and holiday cheer."
        },
        {
          "title": "Visit the Oceanogràfic",
          "details": "Explore the impressive marine park in Valencia, featuring a variety of aquatic species and indoor exhibits, perfect for a winter outing."
        },
        {
          "title": "Hike in the Sierra de Espuña",
          "details": "Take advantage of the mild winter weather for hiking in this beautiful natural park, with trails offering stunning views."
        },
        {
          "title": "Discover the Historic Town of Xàtiva",
          "details": "Wander the charming streets and visit the historic castle, enjoying the quiet beauty of the town in winter."
        },
        {
          "title": "Visit the Bioparc Valencia",
          "details": "Experience the unique zoo that emphasizes conservation and offers an immersive experience with animals in their natural habitats."
        },
        {
          "title": "Explore Castellón’s Beaches",
          "details": "Take peaceful walks along the coastline, enjoying the beautiful scenery and milder temperatures without the summer crowds."
        },
        {
          "title": "Attend Local Festivals",
          "details": "Join in the winter festivities and celebrations, including traditional events that showcase local culture and customs."
        },
        {
          "title": "Experience the Festive Season",
          "details": "Enjoy the festive lights and decorations throughout Valencia, creating a magical atmosphere during the holiday season."
        }
      ]
       },
    { code: "11", name: "Extremadura", tours:[
      {
        "title": "Cáceres Historical Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the medieval city of Cáceres, a UNESCO World Heritage site. Discover its well-preserved old town, visit the stunning Plaza Mayor, and admire the Gothic and Mudejar architecture.",
          "highlights": [
            "Plaza Mayor",
            "Cáceres Cathedral",
            "Palacio de las Veletas",
            "Historic old town walk"
          ]
        }
      },
      {
        "title": "Mérida and Its Roman Ruins",
        "details": {
          "duration": "Full-day",
          "description": "Visit Mérida, known for its rich Roman history. Explore the impressive Roman theater, the amphitheater, and the archaeological museum, all of which reflect its ancient past.",
          "highlights": [
            "Roman Theater",
            "Amphitheater",
            "National Museum of Roman Art",
            "Temple of Diana"
          ]
        }
      },
      {
        "title": "Trujillo Castle and Town Tour",
        "details": {
          "duration": "Half-day",
          "description": "Discover the charming town of Trujillo, famous for its historical significance. Visit the impressive Trujillo Castle, explore the main square, and learn about its conquistador history.",
          "highlights": [
            "Trujillo Castle",
            "Plaza Mayor",
            "Statue of Pizarro",
            "Historic buildings"
          ]
        }
      },
      {
        "title": "Natural Park of Monfragüe Day Trip",
        "details": {
          "duration": "Full-day",
          "description": "Explore the breathtaking landscapes of Monfragüe National Park, renowned for its diverse wildlife. Enjoy guided hikes and birdwatching opportunities, including views of the majestic Spanish imperial eagle.",
          "highlights": [
            "Guided nature hikes",
            "Birdwatching opportunities",
            "Scenic viewpoints",
            "Local flora and fauna"
          ]
        }
      },
      {
        "title": "Zafra and Its Historic Center",
        "details": {
          "duration": "Half-day",
          "description": "Visit the town of Zafra, known for its historic center and charming streets. Explore the Plaza Grande, the Alcázar, and enjoy local delicacies in this picturesque town.",
          "highlights": [
            "Plaza Grande",
            "Alcázar of Zafra",
            "Historic town walk",
            "Local food tasting"
          ]
        }
      },
      {
        "title": "Gastronomic Experience in Extremadura",
        "details": {
          "duration": "Full-day",
          "description": "Delve into the rich culinary traditions of Extremadura. Participate in a cooking class, learn about local ingredients, and enjoy a meal featuring traditional dishes like Jamón Ibérico.",
          "highlights": [
            "Cooking class",
            "Local ingredient tasting",
            "Traditional Extremaduran dishes",
            "Culinary history"
          ]
        }
      }
    ]
    , summer: [
        {
          "title": "Explore Cáceres",
          "details": "Wander through the UNESCO World Heritage-listed old town, with its well-preserved medieval architecture and charming plazas."
        },
        {
          "title": "Visit Mérida",
          "details": "Discover the impressive Roman ruins, including the amphitheater, aqueduct, and the Roman Museum, showcasing the city's rich history."
        },
        {
          "title": "Enjoy Nature in Monfragüe National Park",
          "details": "Explore the stunning landscapes, hike trails, and enjoy birdwatching, especially for the rare Iberian imperial eagle."
        },
        {
          "title": "Relax by the Jerte Valley",
          "details": "Visit during the cherry harvest, enjoy the beautiful scenery, and try local cherry-based products in this picturesque valley."
        },
        {
          "title": "Discover Trujillo",
          "details": "Explore the historic town known for its impressive castle, cobbled streets, and vibrant history tied to the Spanish Conquistadors."
        },
        {
          "title": "Attend Local Festivals",
          "details": "Join in summer festivities, such as the Feria de Mérida, featuring music, dancing, and traditional cuisine."
        },
        {
          "title": "Hike in the Sierra de Gata",
          "details": "Take advantage of the summer weather to hike in this beautiful mountainous region, with stunning views and charming villages."
        },
        {
          "title": "Visit the Alcántara Bridge",
          "details": "Admire the ancient Roman bridge over the Tagus River, a remarkable feat of engineering surrounded by scenic landscapes."
        },
        {
          "title": "Sample Local Cuisine",
          "details": "Indulge in traditional Extremaduran dishes, including Torta del Casar cheese and various meat dishes, at local restaurants."
        },
        {
          "title": "Explore Yuste Monastery",
          "details": "Visit the historical monastery where Charles V retired, surrounded by beautiful gardens and stunning mountain views."
        },
        {
          "title": "Discover the Villages of La Vera",
          "details": "Wander through charming villages like Jaraíz de la Vera and Villanueva de la Vera, known for their picturesque streets and local culture."
        },
        {
          "title": "Experience the Tagus River",
          "details": "Engage in activities like kayaking or fishing on the Tagus River, enjoying the natural beauty and tranquility of the area."
        }
      ]
      , winter: [
        {
          "title": "Visit Cáceres",
          "details": "Explore the charming old town, enjoy the festive holiday decorations, and indulge in local pastries in cozy cafés."
        },
        {
          "title": "Discover Mérida's Roman Ruins",
          "details": "Experience the beauty of the Roman amphitheater and aqueduct, which are less crowded in winter, allowing for leisurely exploration."
        },
        {
          "title": "Hike in Monfragüe National Park",
          "details": "Enjoy the tranquility of the park in winter, with opportunities for birdwatching, particularly for wintering species."
        },
        {
          "title": "Experience Local Christmas Markets",
          "details": "Visit Christmas markets in towns like Cáceres and Mérida, featuring local crafts, food, and festive events."
        },
        {
          "title": "Visit Trujillo Castle",
          "details": "Explore the historic castle and enjoy panoramic views of the surrounding countryside, especially beautiful in the winter light."
        },
        {
          "title": "Sample Traditional Winter Cuisine",
          "details": "Warm up with hearty dishes like migas and traditional stews, showcasing the rich flavors of Extremadura's culinary heritage."
        },
        {
          "title": "Explore Yuste Monastery",
          "details": "Visit the serene monastery where Charles V spent his last years, surrounded by beautiful gardens and winter landscapes."
        },
        {
          "title": "Discover the Villages of La Vera",
          "details": "Wander through picturesque villages like Jaraíz de la Vera, enjoying the tranquility and local winter festivities."
        },
        {
          "title": "Attend Local Festivals",
          "details": "Join in traditional winter celebrations and events, showcasing local culture and customs, particularly during the holiday season."
        },
        {
          "title": "Relax in Local Spas",
          "details": "Unwind in thermal baths and spas in places like Baños de Montemayor, perfect for a relaxing winter getaway."
        },
        {
          "title": "Visit the Alcántara Bridge",
          "details": "Admire the impressive Roman bridge, which offers stunning views and a peaceful atmosphere in the winter months."
        },
        {
          "title": "Explore the Natural Parks",
          "details": "Visit other natural parks in Extremadura, such as the Sierra de Gata, for peaceful walks and the chance to enjoy winter scenery."
        }
      ]
       },
    { code: "12", name: "Galicia", tours:[
      {
        "title": "Santiago de Compostela Historical Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the capital of Galicia, Santiago de Compostela. Visit the iconic Santiago Cathedral, stroll through the historic old town, and learn about the pilgrimage route of the Camino de Santiago.",
          "highlights": [
            "Santiago Cathedral",
            "Plaza del Obradoiro",
            "Old Town UNESCO World Heritage site",
            "Local tapas tasting"
          ]
        }
      },
      {
        "title": "Rías Baixas and Albariño Wine Tour",
        "details": {
          "duration": "Full-day",
          "description": "Discover the beautiful Rías Baixas coastline and its famous Albariño wines. Visit local vineyards, enjoy wine tastings, and explore charming seaside towns like Combarro and Sanxenxo.",
          "highlights": [
            "Vineyard visits",
            "Albariño wine tasting",
            "Combarro village",
            "Coastal scenery"
          ]
        }
      },
      {
        "title": "A Coruña and Tower of Hercules",
        "details": {
          "duration": "Full-day",
          "description": "Visit the vibrant city of A Coruña. Explore the historic Tower of Hercules, walk along the scenic promenade, and enjoy local seafood in the bustling Old Town.",
          "highlights": [
            "Tower of Hercules",
            "Promenade of A Coruña",
            "Old Town exploration",
            "Seafood dining"
          ]
        }
      },
      {
        "title": "The Coast of Death (Costa da Morte) Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the rugged and stunning Coast of Death. Visit charming fishing villages, dramatic cliffs, and the iconic Cape Finisterre, believed to be the end of the world in ancient times.",
          "highlights": [
            "Cape Finisterre",
            "Fishing villages like Muxía",
            "Cliffs and scenic views",
            "Local seafood tasting"
          ]
        }
      },
      {
        "title": "Sierra de Outes Nature Hike",
        "details": {
          "duration": "Half-day",
          "description": "Experience the natural beauty of the Sierra de Outes. Enjoy guided hikes through lush landscapes, discover local flora and fauna, and take in breathtaking views of the Galician countryside.",
          "highlights": [
            "Guided nature hikes",
            "Scenic viewpoints",
            "Wildlife spotting",
            "Local flora exploration"
          ]
        }
      },
      {
        "title": "Pontevedra Cultural Experience",
        "details": {
          "duration": "Half-day",
          "description": "Explore the charming town of Pontevedra, known for its well-preserved historic center. Visit the Basilica of Santa María, stroll through the old town, and enjoy local cuisine.",
          "highlights": [
            "Basilica of Santa María",
            "Historic old town",
            "Local markets",
            "Tapas tasting"
          ]
        }
      }
    ]
,summer: [
        {
          "title": "Relax on the Beaches of Rías Baixas",
          "details": "Enjoy the stunning beaches like Playa de Rodas and Playa de Samil, perfect for sunbathing, swimming, and water sports."
        },
        {
          "title": "Visit Santiago de Compostela",
          "details": "Explore the famous Cathedral of Santiago and the charming old town, experiencing the lively summer atmosphere and cultural events."
        },
        {
          "title": "Discover the Cíes Islands",
          "details": "Take a boat trip to the Cíes Islands, part of the Atlantic Islands of Galicia National Park, known for their pristine beaches and hiking trails."
        },
        {
          "title": "Attend Local Festivals",
          "details": "Join in vibrant summer festivals, such as the Festa do Albariño in Cambados, celebrating local wines and gastronomy."
        },
        {
          "title": "Explore A Coruña",
          "details": "Visit the Tower of Hercules, enjoy the beaches, and stroll through the parks and promenade while soaking up the sun."
        },
        {
          "title": "Hike the Camino de Santiago",
          "details": "Walk part of the famous pilgrimage route, experiencing the beautiful landscapes and charming villages along the way."
        },
        {
          "title": "Taste Galician Seafood",
          "details": "Indulge in fresh seafood dishes at local restaurants, especially octopus (pulpo) and shellfish, enjoying outdoor dining."
        },
        {
          "title": "Visit the Natural Parks",
          "details": "Explore the beautiful natural parks, such as Fragas do Eume and O Invernadeiro, ideal for hiking and enjoying nature."
        },
        {
          "title": "Discover the Historic Town of Lugo",
          "details": "Wander through Lugo's ancient Roman walls, and explore the old town, which is lively and full of summer events."
        },
        {
          "title": "Experience Traditional Galician Music and Dance",
          "details": "Attend local performances and festivals to enjoy traditional music, including bagpipes and folk dances."
        },
        {
          "title": "Visit the Vineyards of Ribeira Sacra",
          "details": "Tour the stunning vineyards along the Sil River, tasting exquisite local wines and enjoying breathtaking views."
        },
        {
          "title": "Explore the Maritime Museums",
          "details": "Learn about Galicia's rich maritime history at museums in places like A Coruña and Vigo, perfect for culture enthusiasts."
        }
      ], winter: [
        {
          "title": "Visit Santiago de Compostela",
          "details": "Explore the stunning Cathedral of Santiago, enjoy the city's cozy cafés, and experience the festive atmosphere during the holiday season."
        },
        {
          "title": "Discover the Cíes Islands",
          "details": "Take a winter boat trip to the Cíes Islands, known for their beautiful landscapes and peaceful surroundings, ideal for hiking and birdwatching."
        },
        {
          "title": "Enjoy Galician Cuisine",
          "details": "Warm up with traditional dishes like caldo gallego (Galician broth) and empanada in local restaurants, perfect for winter comfort."
        },
        {
          "title": "Explore A Coruña",
          "details": "Visit the Tower of Hercules, stroll along the promenade, and enjoy the city's winter lights and decorations."
        },
        {
          "title": "Visit Lugo's Roman Walls",
          "details": "Walk along the well-preserved Roman walls of Lugo, a UNESCO World Heritage site, and explore the charming old town."
        },
        {
          "title": "Attend Local Festivals",
          "details": "Experience traditional winter celebrations, such as Carnival in February, with vibrant parades and local customs."
        },
        {
          "title": "Explore the Rías Baixas",
          "details": "Discover the picturesque coastal region, known for its beautiful estuaries and winter seafood cuisine, especially shellfish."
        },
        {
          "title": "Relax in Hot Springs",
          "details": "Unwind in the natural hot springs at places like Termas de Cuntis, providing a warm escape from the winter chill."
        },
        {
          "title": "Visit the Pazo de Oca",
          "details": "Explore this stunning historic manor house and its gardens, offering a peaceful winter retreat amidst beautiful landscapes."
        },
        {
          "title": "Discover the Galician Coastline",
          "details": "Take a scenic drive along the coast, enjoying breathtaking views and visiting charming fishing villages, even in winter."
        },
        {
          "title": "Hike in the Fragas do Eume Natural Park",
          "details": "Experience the beauty of this natural park with its lush forests and trails, ideal for winter hikes and enjoying nature."
        },
        {
          "title": "Visit the Mercado de Abastos in Santiago",
          "details": "Explore the local market, sampling Galician cheeses, seafood, and other local delicacies, perfect for food lovers."
        }
      ]
       },
    { code: "13", name: "Comunidad de Madrid", tours: [
      {
        "title": "Madrid City Highlights Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the vibrant capital of Spain. Visit the Royal Palace, the famous Prado Museum, and enjoy a leisurely stroll through Retiro Park. Experience local cuisine with a tapas tasting.",
          "highlights": [
            "Royal Palace of Madrid",
            "Prado Museum",
            "Retiro Park",
            "Local tapas tasting"
          ]
        }
      },
      {
        "title": "Day Trip to Toledo",
        "details": {
          "duration": "Full-day",
          "description": "Discover the historic city of Toledo, a UNESCO World Heritage site. Explore its medieval architecture, including the Toledo Cathedral and the Alcázar, and wander through the charming narrow streets.",
          "highlights": [
            "Toledo Cathedral",
            "Alcázar of Toledo",
            "Jewish Quarter",
            "Traditional marzipan tasting"
          ]
        }
      },
      {
        "title": "El Escorial and Valley of the Fallen",
        "details": {
          "duration": "Full-day",
          "description": "Visit the impressive Monastery of El Escorial, a UNESCO World Heritage site, and the nearby Valley of the Fallen, a monumental site commemorating the Spanish Civil War.",
          "highlights": [
            "Monastery of El Escorial",
            "Valley of the Fallen",
            "Beautiful mountain scenery",
            "Guided historical insights"
          ]
        }
      },
      {
        "title": "Day Trip to Ávila",
        "details": {
          "duration": "Full-day",
          "description": "Explore the walled city of Ávila, known for its well-preserved medieval walls. Visit the Ávila Cathedral and the Basilica of San Vicente while enjoying local specialties.",
          "highlights": [
            "Ávila Walls",
            "Ávila Cathedral",
            "Basilica of San Vicente",
            "Local yemas (sweets) tasting"
          ]
        }
      },
      {
        "title": "Chinchón and Its Plaza Mayor",
        "details": {
          "duration": "Half-day",
          "description": "Visit the charming town of Chinchón, famous for its picturesque Plaza Mayor. Enjoy a leisurely walk, explore the local shops, and taste the traditional anise liqueur.",
          "highlights": [
            "Plaza Mayor of Chinchón",
            "Local shops and markets",
            "Chinchón Castle",
            "Anise liqueur tasting"
          ]
        }
      },
      {
        "title": "Wine Tasting in the Madrid Wine Region",
        "details": {
          "duration": "Full-day",
          "description": "Discover the wine region of Madrid, known for its diverse wines. Visit local wineries, learn about the winemaking process, and enjoy tastings paired with traditional food.",
          "highlights": [
            "Winery visits",
            "Wine tasting sessions",
            "Local cuisine pairing",
            "Learn about regional grape varieties"
          ]
        }
      }
    ]
,     summer: [
        {
          "title": "Visit Retiro Park",
          "details": "Enjoy leisurely walks, boat rides on the lake, and picnics in this expansive park, which is a perfect escape from the city heat."
        },
        {
          "title": "Explore Madrid's Art Museums",
          "details": "Visit the renowned Museo del Prado, Reina Sofía, and Thyssen-Bornemisza, enjoying the impressive collections in air-conditioned comfort."
        },
        {
          "title": "Attend Outdoor Festivals and Concerts",
          "details": "Join the lively atmosphere of summer festivals, such as Mad Cool Festival and Veranos de la Villa, featuring music, dance, and cultural events."
        },
        {
          "title": "Discover the Royal Palace",
          "details": "Explore the stunning Royal Palace of Madrid, including its beautiful gardens, and learn about the history of the Spanish monarchy."
        },
        {
          "title": "Stroll Through Malasaña and Chueca",
          "details": "Experience the vibrant neighborhoods of Malasaña and Chueca, known for their trendy shops, cafés, and nightlife."
        },
        {
          "title": "Enjoy Tapas and Outdoor Dining",
          "details": "Savor delicious tapas at outdoor terraces in neighborhoods like La Latina, perfect for people-watching and enjoying the summer evenings."
        },
        {
          "title": "Visit the Temple of Debod",
          "details": "Admire this ancient Egyptian temple at sunset, offering stunning views of the city and a peaceful atmosphere."
        },
        {
          "title": "Take a Day Trip to El Escorial",
          "details": "Explore the historical site of El Escorial, a UNESCO World Heritage site, known for its impressive architecture and beautiful gardens."
        },
        {
          "title": "Relax at Madrid's Rooftop Bars",
          "details": "Enjoy cocktails and breathtaking views of the skyline from popular rooftop bars like Azotea del Círculo and La Terraza del Urban."
        },
        {
          "title": "Visit Parque de Atracciones",
          "details": "Have fun at Madrid’s amusement park, featuring thrilling rides, live shows, and attractions for all ages."
        },
        {
          "title": "Explore the Mercado de San Miguel",
          "details": "Taste a variety of gourmet foods and local delicacies at this famous market, ideal for food lovers seeking summer treats."
        },
        {
          "title": "Discover the Madrid Rio Park",
          "details": "Walk or bike along the beautiful Madrid Rio park, featuring green spaces, playgrounds, and views of the Manzanares River."
        }
      ]
      , winter: [
        {
          "title": "Visit the Christmas Markets",
          "details": "Explore festive markets throughout the city, such as the one in Plaza Mayor, offering local crafts, holiday treats, and seasonal cheer."
        },
        {
          "title": "Ice Skating in Central Madrid",
          "details": "Enjoy outdoor ice skating rinks that pop up in locations like Plaza de Colón and Matadero Madrid, perfect for winter fun."
        },
        {
          "title": "Explore the Royal Palace",
          "details": "Visit the stunning Royal Palace of Madrid and its beautiful gardens, often less crowded in winter, providing a serene experience."
        },
        {
          "title": "Attend the Three Kings Parade",
          "details": "Experience the magical parade on January 5th, celebrating the arrival of the Three Wise Men with floats, music, and festivities."
        },
        {
          "title": "Enjoy Madrid's Art Museums",
          "details": "Escape the cold by visiting world-class museums like the Prado, Reina Sofía, and Thyssen-Bornemisza, showcasing incredible art collections."
        },
        {
          "title": "Warm Up with Hot Chocolate and Churros",
          "details": "Indulge in the classic Spanish treat of churros dipped in thick hot chocolate at famous spots like Chocolatería San Ginés."
        },
        {
          "title": "Explore Madrid's Historic Neighborhoods",
          "details": "Wander through charming neighborhoods like Malasaña and La Latina, enjoying their unique architecture and cozy bars."
        },
        {
          "title": "Visit the Temple of Debod",
          "details": "Admire this ancient Egyptian temple, especially beautiful at sunset, offering stunning views and a tranquil winter atmosphere."
        },
        {
          "title": "Take a Day Trip to El Escorial",
          "details": "Explore the impressive Royal Monastery of El Escorial, a UNESCO World Heritage site, surrounded by beautiful winter landscapes."
        },
        {
          "title": "Experience Madrid’s Rooftop Bars",
          "details": "Enjoy warm drinks and panoramic views of the city skyline from rooftop bars, which often feature heated areas for winter comfort."
        },
        {
          "title": "Attend Local Festivals and Events",
          "details": "Join in various cultural events and performances held throughout the winter, showcasing local traditions and arts."
        },
        {
          "title": "Relax in Parque del Retiro",
          "details": "Take a winter stroll through Retiro Park, enjoy the peaceful atmosphere, and warm up in nearby cafés with a view of the gardens."
        }
      ]
       },
    { code: "14", name: "Región de Murcia", tours: [
      {
        "title": "Murcia City Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the vibrant city of Murcia. Visit the stunning Cathedral of Murcia, stroll through the historic center, and enjoy local specialties such as zarangollo and pastel de carne.",
          "highlights": [
            "Cathedral of Murcia",
            "Plaza de las Flores",
            "Casino de Murcia",
            "Local tapas tasting"
          ]
        }
      },
      {
        "title": "Cartagena Historical Tour",
        "details": {
          "duration": "Full-day",
          "description": "Discover the rich history of Cartagena. Visit the Roman Theatre, the National Museum of Underwater Archaeology, and explore the charming old town with its beautiful architecture.",
          "highlights": [
            "Roman Theatre",
            "National Museum of Underwater Archaeology",
            "Cartagena Port",
            "Historic buildings tour"
          ]
        }
      },
      {
        "title": "Sierra Espuña Natural Park Hiking",
        "details": {
          "duration": "Full-day",
          "description": "Experience the natural beauty of Sierra Espuña Natural Park. Enjoy guided hikes through stunning landscapes, discover local flora and fauna, and take in panoramic views.",
          "highlights": [
            "Guided nature hikes",
            "Scenic viewpoints",
            "Wildlife spotting",
            "Lush pine forests"
          ]
        }
      },
      {
        "title": "Cabo de Palos and La Manga",
        "details": {
          "duration": "Half-day",
          "description": "Visit the beautiful Cabo de Palos and the famous La Manga strip. Enjoy the stunning coastal views, relax on the beach, and explore the marine life with snorkeling options.",
          "highlights": [
            "Cabo de Palos Lighthouse",
            "La Manga beaches",
            "Marine reserve exploration",
            "Local seafood dining"
          ]
        }
      },
      {
        "title": "Bodega Tour and Wine Tasting",
        "details": {
          "duration": "Full-day",
          "description": "Discover the wine region of Murcia, known for its quality wines. Visit local bodegas, learn about the winemaking process, and enjoy tastings of exquisite wines paired with regional dishes.",
          "highlights": [
            "Winery visits",
            "Wine tasting sessions",
            "Local gastronomy pairing",
            "Learn about regional grape varieties"
          ]
        }
      },
      {
        "title": "Elche and the Palm Grove",
        "details": {
          "duration": "Full-day",
          "description": "Explore the nearby city of Elche, famous for its UNESCO-listed palm grove. Visit the Palm Grove, the Huerto del Cura, and the Basilica of Santa Maria.",
          "highlights": [
            "Palm Grove of Elche",
            "Huerto del Cura",
            "Basilica of Santa Maria",
            "Local cuisine tasting"
          ]
        }
      }
    ]
,     summer: [
        {
          "title": "Relax on the Beaches of the Costa Cálida",
          "details": "Enjoy the sun and sea at beautiful beaches like La Manga, Calblanque, and San Javier, perfect for swimming and water sports."
        },
        {
          "title": "Explore the Historic City of Murcia",
          "details": "Visit the stunning Cathedral of Murcia and the vibrant Plaza de las Flores, and enjoy outdoor dining at local tapas bars."
        },
        {
          "title": "Discover Cartagena's Rich History",
          "details": "Explore the ancient Roman Theatre, the National Museum of Underwater Archaeology, and the charming harbor area."
        },
        {
          "title": "Hike in Sierra Espuña",
          "details": "Take advantage of the cooler mornings for hiking and exploring the beautiful trails in this stunning natural park."
        },
        {
          "title": "Visit the Natural Park of Calblanque",
          "details": "Discover pristine beaches and unspoiled landscapes, ideal for hiking, swimming, and enjoying nature in a tranquil setting."
        },
        {
          "title": "Attend Local Festivals",
          "details": "Experience vibrant summer festivals, such as the Festival de La Huerta, celebrating local culture with music, food, and activities."
        },
        {
          "title": "Sample Murcian Cuisine",
          "details": "Indulge in local dishes like zarangollo and pastel de carne at outdoor restaurants, enjoying the regional flavors."
        },
        {
          "title": "Explore the Salinas y Arenales de San Pedro",
          "details": "Visit this stunning natural reserve, known for its salt flats and unique biodiversity, perfect for birdwatching."
        },
        {
          "title": "Go Wine Tasting in Jumilla",
          "details": "Tour local wineries and enjoy tastings of Jumilla's renowned red wines while learning about the winemaking process."
        },
        {
          "title": "Enjoy Water Sports",
          "details": "Try activities like paddleboarding, jet skiing, and snorkeling along the Costa Cálida's beautiful coastline."
        },
        {
          "title": "Visit the Murcia Fair",
          "details": "Experience the annual Feria de Murcia in September, featuring concerts, parades, and a lively atmosphere celebrating local traditions."
        },
        {
          "title": "Take a Day Trip to Águilas",
          "details": "Enjoy the charming coastal town of Águilas, known for its beaches, seafood, and beautiful promenade."
        }
      ]
      , winter: [
        {
          "title": "Explore the Historic City of Murcia",
          "details": "Visit the stunning Cathedral of Murcia and the beautiful Plaza de las Flores, enjoying the city’s festive decorations during the holiday season."
        },
        {
          "title": "Visit Cartagena's Roman Theatre",
          "details": "Discover the ancient Roman Theatre and explore the charming streets of Cartagena, known for its rich history and maritime heritage."
        },
        {
          "title": "Enjoy the Warmth of Local Cuisine",
          "details": "Indulge in hearty winter dishes like zarangollo (a vegetable omelette) and pisto murciano (a ratatouille-like dish) at local restaurants."
        },
        {
          "title": "Attend the Three Kings Parade",
          "details": "Experience the vibrant parade on January 5th in various towns, celebrating the arrival of the Three Wise Men with floats and festivities."
        },
        {
          "title": "Discover the Natural Park of Calblanque",
          "details": "Enjoy the beautiful landscapes and tranquil beaches of this natural park, perfect for peaceful winter hikes and enjoying nature."
        },
        {
          "title": "Visit the Salinas y Arenales de San Pedro",
          "details": "Explore the stunning salt flats and natural reserves, ideal for birdwatching and taking in the scenic winter landscapes."
        },
        {
          "title": "Relax in the Spa Town of Archena",
          "details": "Unwind in the thermal baths of Archena, known for its therapeutic properties, providing a relaxing winter escape."
        },
        {
          "title": "Tour the Vineyards in Jumilla",
          "details": "Visit local wineries in Jumilla, famous for its wines, and enjoy tastings while learning about the wine-making process."
        },
        {
          "title": "Explore the Historic Town of Lorca",
          "details": "Wander through the old town, visiting the impressive Lorca Castle and enjoying the local culture and history."
        },
        {
          "title": "Enjoy Outdoor Activities in Sierra Espuña",
          "details": "Take advantage of the mild winter weather for hiking, biking, or exploring the natural beauty of this stunning mountain range."
        },
        {
          "title": "Visit Local Art Galleries",
          "details": "Discover contemporary art in local galleries and exhibitions in cities like Murcia and Cartagena, often featuring local artists."
        },
        {
          "title": "Experience the Local Fiestas",
          "details": "Join in various winter festivities and traditional celebrations throughout the region, showcasing the vibrant culture of Murcia."
        }
      ]
       },
    { code: "15", name: "Comunidad Foral de Navarra", tours: [
      {
        "title": "Pamplona City Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the vibrant city of Pamplona, famous for the Running of the Bulls. Visit the historic old town, admire the Gothic Cathedral, and stroll through the beautiful Parque de la Taconera.",
          "highlights": [
            "Pamplona Cathedral",
            "Plaza del Castillo",
            "San Fermín Festival insights",
            "Local pintxos tasting"
          ]
        }
      },
      {
        "title": "Visit to the Bardenas Reales",
        "details": {
          "duration": "Full-day",
          "description": "Discover the unique landscapes of the Bardenas Reales, a semi-desert natural park. Enjoy guided tours to see its striking rock formations and rich biodiversity.",
          "highlights": [
            "Bardenas Reales Natural Park",
            "Scenic viewpoints",
            "Guided walking tours",
            "Photography opportunities"
          ]
        }
      },
      {
        "title": "Roncesvalles and the Pyrenees",
        "details": {
          "duration": "Full-day",
          "description": "Visit the historic town of Roncesvalles, the traditional starting point of the Camino de Santiago. Explore its monastery and enjoy breathtaking views of the Pyrenees mountains.",
          "highlights": [
            "Colegiata de Roncesvalles",
            "St. James' Way history",
            "Pyrenean landscapes",
            "Local cuisine tasting"
          ]
        }
      },
      {
        "title": "Olite Castle and Wine Tasting",
        "details": {
          "duration": "Half-day",
          "description": "Explore the magnificent Olite Castle, one of the best-preserved medieval castles in Spain. After the tour, enjoy wine tastings from the nearby Navarra wine region.",
          "highlights": [
            "Olite Castle",
            "Local wineries",
            "Wine tasting sessions",
            "Historic town exploration"
          ]
        }
      },
      {
        "title": "The Valley of Baztán Tour",
        "details": {
          "duration": "Full-day",
          "description": "Discover the picturesque Baztán Valley, known for its lush landscapes and charming villages. Visit traditional Basque homes and sample local cheeses and pastries.",
          "highlights": [
            "Charming villages like Elizondo",
            "Local cheese tasting",
            "Beautiful nature walks",
            "Cultural insights"
          ]
        }
      },
      {
        "title": "San Sebastián Day Trip",
        "details": {
          "duration": "Full-day",
          "description": "Take a day trip to the beautiful coastal city of San Sebastián. Enjoy its stunning beaches, walk along La Concha promenade, and indulge in delicious pintxos.",
          "highlights": [
            "La Concha beach",
            "Old Town (Parte Vieja)",
            "Pintxos bar hopping",
            "Scenic viewpoints"
          ]
        }
      }
    ]
    ,summer: [
        {
          "title": "Explore Pamplona",
          "details": "Wander through the historic streets of Pamplona, visiting the iconic Plaza del Castillo and enjoying the vibrant outdoor cafés."
        },
        {
          "title": "Attend the San Fermín Festival",
          "details": "Experience the famous San Fermín Festival in July, featuring the Running of the Bulls, traditional music, and colorful parades."
        },
        {
          "title": "Visit the Navarre Pyrenees",
          "details": "Discover the stunning landscapes, hiking trails, and outdoor activities in the Navarre Pyrenees, perfect for nature lovers."
        },
        {
          "title": "Tour the Bardenas Reales",
          "details": "Explore this unique semi-desert landscape, known for its surreal rock formations and diverse wildlife, ideal for hiking and photography."
        },
        {
          "title": "Wine Tasting in the Rioja Alta",
          "details": "Visit local wineries in the Rioja Alta region, sampling exquisite wines and enjoying the beautiful vineyard landscapes."
        },
        {
          "title": "Discover the Medieval Town of Olite",
          "details": "Explore the stunning Olite Castle and the charming streets of this medieval town, known for its historical significance and architecture."
        },
        {
          "title": "Hike in the Irati Forest",
          "details": "Experience one of Europe’s largest beech and fir forests, with numerous trails for hiking and the chance to see diverse wildlife."
        },
        {
          "title": "Relax in the Thermal Springs of Baños de Benasque",
          "details": "Enjoy a day of relaxation in the thermal baths, surrounded by beautiful mountain scenery, perfect for unwinding in summer."
        },
        {
          "title": "Visit the Monastery of Leyre",
          "details": "Explore this historic monastery, set in a beautiful location, known for its stunning architecture and peaceful surroundings."
        },
        {
          "title": "Participate in Outdoor Sports",
          "details": "Engage in activities such as kayaking, rafting, or cycling along the rivers and trails that Navarra has to offer."
        },
        {
          "title": "Explore the Historical Sites of Estella",
          "details": "Wander through the charming town of Estella, rich in history and known for its medieval architecture and vibrant culture."
        },
        {
          "title": "Taste Local Cuisine",
          "details": "Indulge in traditional Navarrese dishes at local restaurants, including dishes like chistorra and pimientos del piquillo."
        }
      ]
      , winter: [
        {
          "title": "Visit Pamplona's Christmas Market",
          "details": "Explore the festive Christmas market in Pamplona, where you can find local crafts, holiday treats, and enjoy the festive atmosphere."
        },
        {
          "title": "Skiing in the Navarre Pyrenees",
          "details": "Hit the slopes at ski resorts like Abodi and El Ferial, offering a range of winter sports for all skill levels."
        },
        {
          "title": "Discover the Bardenas Reales in Winter",
          "details": "Experience the unique landscapes of this semi-desert region, with its stunning rock formations and quiet trails, perfect for photography."
        },
        {
          "title": "Explore the Historic Town of Olite",
          "details": "Visit the impressive Olite Castle and the charming old town, which often features fewer tourists in winter, allowing for a peaceful visit."
        },
        {
          "title": "Hike in the Irati Forest",
          "details": "Enjoy winter hiking in one of Europe’s largest beech and fir forests, with trails offering stunning views and a tranquil atmosphere."
        },
        {
          "title": "Warm Up with Local Cuisine",
          "details": "Indulge in hearty winter dishes like menestra (vegetable stew) and local wines at cozy restaurants throughout the region."
        },
        {
          "title": "Attend the San Sebastián Day Celebrations",
          "details": "Join the festivities in January in Pamplona, featuring parades, music, and delicious pintxos to celebrate the city’s patron saint."
        },
        {
          "title": "Relax in Thermal Springs",
          "details": "Unwind in the natural thermal baths at places like Baños de Benasque, enjoying the warm waters amidst beautiful winter landscapes."
        },
        {
          "title": "Visit the Monastery of Leyre",
          "details": "Explore this historic monastery, which is peaceful in winter and offers beautiful views of the surrounding snowy landscape."
        },
        {
          "title": "Experience Local Fairs and Markets",
          "details": "Visit local winter fairs and markets to discover artisan crafts, traditional foods, and seasonal delicacies unique to the region."
        },
        {
          "title": "Enjoy Birdwatching in the Wetlands",
          "details": "Explore the wetlands of Bañez, a great spot for winter birdwatching, where you can see various migratory species."
        },
        {
          "title": "Take a Scenic Drive through the Pyrenees",
          "details": "Enjoy breathtaking views while driving through the Navarre Pyrenees, especially picturesque when dusted with snow."
        }
      ]
       },
    { code: "16", name: "País Vasco", tours: [
      {
        "title": "San Sebastián City Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the picturesque coastal city of San Sebastián. Stroll along La Concha beach, visit the Old Town, and enjoy a guided pintxos tasting tour at local bars.",
          "highlights": [
            "La Concha beach",
            "Old Town (Parte Vieja)",
            "Pintxos tasting",
            "Mount Igueldo views"
          ]
        }
      },
      {
        "title": "Bilbao Highlights Tour",
        "details": {
          "duration": "Full-day",
          "description": "Discover the vibrant city of Bilbao. Visit the iconic Guggenheim Museum, stroll through the Casco Viejo, and experience the city's rich culinary scene with a local food tour.",
          "highlights": [
            "Guggenheim Museum",
            "Casco Viejo (Old Town)",
            "Ribera Market",
            "Local food tasting"
          ]
        }
      },
      {
        "title": "Gernika and Urdaibai Biosphere Reserve",
        "details": {
          "duration": "Full-day",
          "description": "Visit the historic town of Gernika, known for its significance in Basque history. Explore the Urdaibai Biosphere Reserve, home to stunning landscapes and diverse wildlife.",
          "highlights": [
            "Gernika Peace Museum",
            "Gernika Tree of Gernika",
            "Urdaibai Biosphere Reserve",
            "Scenic nature walks"
          ]
        }
      },
      {
        "title": "Wine and Cider Tour in Getaria",
        "details": {
          "duration": "Full-day",
          "description": "Experience the culinary delights of the Basque Country with a wine and cider tour in Getaria. Visit local txakoli wineries, taste traditional cider, and enjoy seafood dishes.",
          "highlights": [
            "Txakoli wine tasting",
            "Cider house visit",
            "Seafood dining",
            "Getaria old town exploration"
          ]
        }
      },
      {
        "title": "Picos de Europa National Park Hiking",
        "details": {
          "duration": "Full-day",
          "description": "Explore the breathtaking landscapes of Picos de Europa National Park. Enjoy guided hikes through rugged mountains, lush valleys, and stunning vistas.",
          "highlights": [
            "Guided nature hikes",
            "Stunning mountain views",
            "Wildlife spotting",
            "Local flora and fauna"
          ]
        }
      },
      {
        "title": "Zarautz and Its Beaches",
        "details": {
          "duration": "Half-day",
          "description": "Visit the coastal town of Zarautz, known for its long beach and vibrant atmosphere. Relax on the beach, explore the town, and try local seafood.",
          "highlights": [
            "Zarautz beach",
            "Promenade walk",
            "Local seafood tasting",
            "Surfing opportunities"
          ]
        }
      }
    ]
    ,summer: [
        {
          "title": "Relax on the Beaches of San Sebastián",
          "details": "Enjoy sunbathing and swimming at La Concha and Zurriola beaches, both popular spots for locals and tourists alike."
        },
        {
          "title": "Explore Bilbao’s Old Town",
          "details": "Wander through the charming streets of Casco Viejo, visit local shops, and enjoy tapas in the many bars and restaurants."
        },
        {
          "title": "Attend the Aste Nagusia Festival",
          "details": "Experience Bilbao’s biggest festival in August, featuring concerts, parades, and traditional Basque cultural events."
        },
        {
          "title": "Visit the Guggenheim Museum",
          "details": "Explore contemporary art exhibitions in this iconic building, and enjoy the surrounding gardens and sculptures."
        },
        {
          "title": "Hike in the Urkiola Natural Park",
          "details": "Take advantage of the warm weather to hike in this stunning park, known for its beautiful landscapes and diverse flora and fauna."
        },
        {
          "title": "Discover the Coastal Town of Getaria",
          "details": "Visit this charming fishing village, known for its beautiful beaches, seafood restaurants, and the Cristobal Balenciaga Museum."
        },
        {
          "title": "Explore the Basque Wine Country",
          "details": "Tour the vineyards of the Rioja Alavesa region, sampling delicious local wines and enjoying scenic views of the countryside."
        },
        {
          "title": "Enjoy Pintxos in San Sebastián",
          "details": "Go on a pintxos crawl, tasting various small dishes in the city’s many bars, each offering unique culinary delights."
        },
        {
          "title": "Attend the San Sebastián Jazz Festival",
          "details": "Enjoy live jazz performances at this renowned festival held in July, attracting top international artists."
        },
        {
          "title": "Explore the Bay of Biscay Coast",
          "details": "Take a scenic drive along the coast, stopping at picturesque villages like Mundaka and Bermeo, famous for surfing and seafood."
        },
        {
          "title": "Visit the Beaches of Zarautz",
          "details": "Spend a day at Zarautz Beach, known for its long sandy stretch, perfect for sunbathing, surfing, and beach sports."
        },
        {
          "title": "Take a Boat Tour of the Basque Coast",
          "details": "Experience the stunning coastline from the water, with opportunities for swimming and exploring hidden coves and beaches."
        }
      ]
      , winter: [
        {
          "title": "Explore San Sebastián",
          "details": "Wander through the picturesque streets of San Sebastián, enjoy the stunning views from Mount Igueldo, and savor delicious pintxos in local bars."
        },
        {
          "title": "Visit the Guggenheim Museum in Bilbao",
          "details": "Experience contemporary art at the iconic Guggenheim Museum, and enjoy the surrounding waterfront area, which is especially beautiful in winter."
        },
        {
          "title": "Attend the Fiestas de Santo Tomás",
          "details": "Join in the festivities on December 21 in Donostia/San Sebastián, celebrating with traditional food markets and local music."
        },
        {
          "title": "Ski in the Pyrenees",
          "details": "Hit the slopes at ski resorts like Baqueira Beret or La Pierre Saint Martin, perfect for skiing and snowboarding enthusiasts."
        },
        {
          "title": "Enjoy Basque Cuisine",
          "details": "Warm up with hearty Basque dishes such as bacalao al pil-pil (cod) and traditional stews in cozy restaurants throughout the region."
        },
        {
          "title": "Visit the Basque Coast",
          "details": "Experience the dramatic coastal scenery along the Basque coast, with beautiful winter walks and fresh seafood in quaint fishing villages."
        },
        {
          "title": "Explore the Historic Town of Getaria",
          "details": "Discover this charming fishing village known for its beautiful architecture, fresh seafood, and wine tasting in winter."
        },
        {
          "title": "Take a Wine Tour in Rioja Alavesa",
          "details": "Visit local wineries in the Rioja Alavesa region, sampling excellent wines while enjoying the serene winter landscapes."
        },
        {
          "title": "Attend Local Cultural Events",
          "details": "Enjoy winter concerts, theater performances, and cultural events held in cities like Bilbao and San Sebastián."
        },
        {
          "title": "Visit the Chillida-Leku Museum",
          "details": "Explore the outdoor sculpture park dedicated to the works of artist Eduardo Chillida, set in a beautiful natural landscape."
        },
        {
          "title": "Discover the Basque Country's Unique Architecture",
          "details": "Wander through charming villages like Hondarribia and Getxo, known for their traditional Basque architecture and coastal views."
        },
        {
          "title": "Experience the San Sebastián Film Festival",
          "details": "If your visit coincides with the festival dates, enjoy screenings of films from around the world in one of Spain's most beautiful cities."
        }
      ]
       },
    { code: "17", name: "La Rioja", tours: [
      {
        "title": "La Rioja Wine Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the renowned wine region of La Rioja. Visit prestigious wineries, learn about the winemaking process, and enjoy tastings of exceptional Rioja wines paired with local dishes.",
          "highlights": [
            "Visit to multiple wineries",
            "Wine tasting sessions",
            "Local gastronomy pairing",
            "Guided vineyard tour"
          ]
        }
      },
      {
        "title": "Logroño City Tour",
        "details": {
          "duration": "Full-day",
          "description": "Discover the vibrant capital of La Rioja, Logroño. Visit historic sites, including the Cathedral of Santa María de la Redonda, and enjoy a pintxos tasting tour in the famous Calle del Laurel.",
          "highlights": [
            "Cathedral of Santa María de la Redonda",
            "Pintxos bar hopping",
            "Calle del Laurel",
            "Local market visit"
          ]
        }
      },
      {
        "title": "Haro Wine and Gastronomy Experience",
        "details": {
          "duration": "Half-day",
          "description": "Visit the historic town of Haro, the heart of La Rioja wine country. Tour traditional wineries, sample wines, and savor regional dishes at a local restaurant.",
          "highlights": [
            "Haro wine cellars",
            "Wine tasting",
            "Traditional La Rioja cuisine",
            "Scenic vineyard views"
          ]
        }
      },
      {
        "title": "Briones and the Rioja Wine Museum",
        "details": {
          "duration": "Half-day",
          "description": "Explore the charming village of Briones. Visit the Rioja Wine Museum to learn about the region's winemaking history, followed by a leisurely walk through the picturesque town.",
          "highlights": [
            "Rioja Wine Museum",
            "Briones village exploration",
            "Wine tasting options",
            "Beautiful landscapes"
          ]
        }
      },
      {
        "title": "Visit to the Monastery of San Millán de la Cogolla",
        "details": {
          "duration": "Full-day",
          "description": "Discover the UNESCO World Heritage site of San Millán de la Cogolla. Explore the historic monastery and its stunning architecture, and enjoy a picnic amidst the beautiful surroundings.",
          "highlights": [
            "Monastery of San Millán",
            "Historical insights",
            "Scenic picnic area",
            "Local gastronomy"
          ]
        }
      },
      {
        "title": "Nature and Hiking in Sierra de la Demanda",
        "details": {
          "duration": "Full-day",
          "description": "Experience the natural beauty of the Sierra de la Demanda. Enjoy guided hikes through stunning landscapes, including forests, mountains, and rivers, with opportunities for wildlife spotting.",
          "highlights": [
            "Guided nature hikes",
            "Scenic mountain views",
            "Wildlife spotting",
            "Local flora and fauna"
          ]
        }
      }
    ]
,     summer: [
        {
          "title": "Wine Tasting in La Rioja",
          "details": "Visit renowned wineries and vineyards, sampling some of the region's finest wines and learning about the winemaking process."
        },
        {
          "title": "Explore Logroño",
          "details": "Wander through the charming streets of Logroño, visiting the historic old town, local shops, and vibrant tapas bars."
        },
        {
          "title": "Attend the Haro Wine Festival",
          "details": "Join the lively celebrations in June featuring wine tastings, music, and traditional games in the beautiful town of Haro."
        },
        {
          "title": "Hike in the Sierra de Cebollera Natural Park",
          "details": "Enjoy scenic hikes in this stunning natural park, known for its beautiful landscapes, diverse wildlife, and clear rivers."
        },
        {
          "title": "Discover the Monastery of San Millán de la Cogolla",
          "details": "Visit this UNESCO World Heritage site, famous for its historical significance and beautiful architecture."
        },
        {
          "title": "Relax in the Rioja Alavesa Region",
          "details": "Explore picturesque villages like Laguardia and Elciego, enjoying their historic charm and surrounding vineyards."
        },
        {
          "title": "Attend Local Festivals and Fairs",
          "details": "Experience traditional festivals throughout the summer, featuring music, dance, and local cuisine in various towns."
        },
        {
          "title": "Enjoy Gastronomy at the Local Restaurants",
          "details": "Savor traditional La Rioja dishes like patatas a la riojana (potato stew) and chuletillas al sarmiento (grilled lamb)."
        },
        {
          "title": "Explore the Ebro River",
          "details": "Take a leisurely stroll or bike ride along the Ebro River, enjoying the natural beauty and peaceful surroundings."
        },
        {
          "title": "Visit the Rioja Museum",
          "details": "Learn about the region's rich wine history and culture at the interactive museum located in Logroño."
        },
        {
          "title": "Take a Hot Air Balloon Ride",
          "details": "Experience breathtaking views of the vineyards and landscapes from above with a hot air balloon ride over La Rioja."
        },
        {
          "title": "Explore the Vineyards by Bike",
          "details": "Rent a bike and tour the scenic vineyards and countryside, enjoying the warm summer weather and beautiful landscapes."
        }
      ]
      , winter: [
        {
          "title": "Visit the Wine Cellars",
          "details": "Explore the region’s famous wine cellars, where you can enjoy guided tours and tastings, often less crowded in winter."
        },
        {
          "title": "Discover Logroño’s Gastronomy",
          "details": "Indulge in local winter dishes at cozy restaurants, such as patatas a la riojana and cordero asado (roast lamb)."
        },
        {
          "title": "Attend the San Sebastián Day Celebrations",
          "details": "Join the festivities in January, celebrating the patron saint of Logroño with music, food, and parades."
        },
        {
          "title": "Explore the Sierra de Cebollera Natural Park",
          "details": "Enjoy winter hiking and scenic walks in this beautiful natural park, where you can appreciate the serene landscapes."
        },
        {
          "title": "Visit the Monastery of San Millán de la Cogolla",
          "details": "Discover this UNESCO World Heritage site, often less crowded in winter, showcasing its historical and architectural significance."
        },
        {
          "title": "Warm Up with Local Wine",
          "details": "Try a glass of local red wine at wine bars, enjoying its rich flavors, perfect for the colder months."
        },
        {
          "title": "Attend Local Winter Festivals",
          "details": "Experience traditional winter festivals in towns across La Rioja, celebrating local culture with food, music, and activities."
        },
        {
          "title": "Explore Historic Towns like Haro",
          "details": "Wander through the charming streets of Haro, visiting its beautiful squares and enjoying the festive winter atmosphere."
        },
        {
          "title": "Enjoy a Spa Day",
          "details": "Relax in one of La Rioja’s wellness centers, indulging in thermal baths and spa treatments to unwind during the winter."
        },
        {
          "title": "Visit the Rioja Museum",
          "details": "Learn about the region’s wine history and culture at the interactive Rioja Museum in Logroño, ideal for a winter outing."
        },
        {
          "title": "Take Scenic Drives through Vineyards",
          "details": "Enjoy picturesque winter landscapes while driving through the vineyard-covered countryside, which is especially tranquil in winter."
        },
        {
          "title": "Explore the Ebro Riverbanks",
          "details": "Take leisurely walks along the Ebro River, enjoying the peaceful surroundings and winter scenery."
        }
      ]
       },
    { code: "18", name: "Ciudad Autónoma de Ceuta", tours:[
      {
        "title": "Ceuta City Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the rich history and culture of Ceuta. Visit the Royal Walls, the Cathedral of St. Mary of the Assumption, and enjoy panoramic views from the Monte Hacho.",
          "highlights": [
            "Royal Walls of Ceuta",
            "Cathedral of St. Mary",
            "Monte Hacho viewpoint",
            "Local cuisine tasting"
          ]
        }
      },
      {
        "title": "Tarifa and Beach Day Trip",
        "details": {
          "duration": "Full-day",
          "description": "Take a scenic trip to Tarifa, known for its stunning beaches and vibrant atmosphere. Enjoy sunbathing, water sports, or exploring the charming old town.",
          "highlights": [
            "Beaches of Tarifa",
            "Old Town exploration",
            "Water sports activities",
            "Lunch at a beachside restaurant"
          ]
        }
      },
      {
        "title": "Cultural and Historical Tour of Ceuta",
        "details": {
          "duration": "Half-day",
          "description": "Delve into the cultural heritage of Ceuta. Visit the Museum of Ceuta, the Arab Baths, and the Plaza de África while learning about the city's diverse influences.",
          "highlights": [
            "Museum of Ceuta",
            "Arab Baths",
            "Plaza de África",
            "Guided historical insights"
          ]
        }
      },
      {
        "title": "Nature Excursion to Parque Marítimo del Mediterráneo",
        "details": {
          "duration": "Half-day",
          "description": "Enjoy a relaxing day at the Parque Marítimo del Mediterráneo. Take a leisurely walk along the waterfront, swim in the pools, or enjoy a picnic in the lush gardens.",
          "highlights": [
            "Waterfront promenade",
            "Swimming pools",
            "Picnic areas",
            "Children's playground"
          ]
        }
      },
      {
        "title": "Excursion to the African Coast",
        "details": {
          "duration": "Full-day",
          "description": "Take a boat trip to the nearby Moroccan coast. Visit the charming town of Tétouan, known for its medina and vibrant markets, while enjoying a taste of Moroccan culture.",
          "highlights": [
            "Boat trip to Tétouan",
            "Exploration of the medina",
            "Local market visit",
            "Moroccan cuisine tasting"
          ]
        }
      },
      {
        "title": "Hiking in the Surrounding Hills",
        "details": {
          "duration": "Half-day",
          "description": "Discover the natural beauty surrounding Ceuta with guided hikes in the nearby hills. Enjoy breathtaking views of the Strait of Gibraltar and the African coast.",
          "highlights": [
            "Guided hiking tours",
            "Stunning coastal views",
            "Local flora and fauna",
            "Wildlife spotting opportunities"
          ]
        }
      }
    ]
    , summer: [
        {
          "title": "Relax at the Beaches",
          "details": "Enjoy the sun and sea at popular beaches like Playa de la Ribera and Playa de los Ríos, perfect for swimming and sunbathing."
        },
        {
          "title": "Explore the Historic City Center",
          "details": "Wander through the charming streets, visiting landmarks like the Cathedral of Ceuta and the Royal Walls, which are vibrant in summer."
        },
        {
          "title": "Visit the Maritime Park of Ceuta",
          "details": "Discover the marine life at the aquarium and enjoy educational exhibits, making it a fun outing for families."
        },
        {
          "title": "Hike in Monte Hacho Natural Park",
          "details": "Take advantage of the warm weather to explore hiking trails with breathtaking views of the city and the Mediterranean Sea."
        },
        {
          "title": "Attend Local Festivals and Events",
          "details": "Join in summer celebrations and cultural events, experiencing Ceuta's rich traditions and community spirit."
        },
        {
          "title": "Taste Local Cuisine at Outdoor Restaurants",
          "details": "Savor traditional dishes like tagine and fresh seafood at local eateries with outdoor seating, enjoying the lively atmosphere."
        },
        {
          "title": "Visit the Fort of San Felipe",
          "details": "Explore this historic fortress and enjoy panoramic views of the Strait of Gibraltar and the surrounding areas."
        },
        {
          "title": "Stroll Through the Gardens of the Murallas Reales",
          "details": "Enjoy a peaceful walk in these historic gardens, which offer beautiful scenery and a chance to relax in nature."
        },
        {
          "title": "Take a Boat Trip to Nearby Beaches",
          "details": "Explore secluded beaches and coves accessible by boat, perfect for a day of swimming and relaxation."
        },
        {
          "title": "Discover the Plaza de Africa",
          "details": "Visit this vibrant square, surrounded by important buildings, and enjoy local shops and cafes."
        },
        {
          "title": "Shop at Local Markets",
          "details": "Browse bustling markets for unique crafts, spices, and souvenirs, immersing yourself in the local culture."
        },
        {
          "title": "Enjoy Water Sports",
          "details": "Try activities like kayaking, jet skiing, or paddleboarding at the beach for an adventurous day in the sun."
        }
      ]
      , winter: [
        {
          "title": "Explore the Historic City Center",
          "details": "Wander through Ceuta’s charming streets, visiting historic sites like the Cathedral of Ceuta and the Royal Walls."
        },
        {
          "title": "Visit the Museum of Ceuta",
          "details": "Discover the rich history and culture of Ceuta at the local museum, showcasing archaeological finds and art."
        },
        {
          "title": "Enjoy the Mild Winter Weather at the Beaches",
          "details": "Take leisurely walks along the beautiful beaches like Playa de la Ribera, enjoying the serene winter atmosphere."
        },
        {
          "title": "Hike in the Monte Hacho Natural Park",
          "details": "Explore the scenic trails of this natural park, offering stunning views of the city and the Mediterranean Sea."
        },
        {
          "title": "Discover the Maritime Park of Ceuta",
          "details": "Visit the aquarium and marine park, perfect for learning about local marine life and enjoying the peaceful surroundings."
        },
        {
          "title": "Taste Local Cuisine",
          "details": "Warm up with traditional dishes at local restaurants, such as tagine and seafood specialties, reflecting the region's diverse culture."
        },
        {
          "title": "Visit the Plaza de Africa",
          "details": "Relax in this vibrant square, surrounded by important buildings, and enjoy the local atmosphere."
        },
        {
          "title": "Explore the Fort of San Felipe",
          "details": "Visit this historic fortress and enjoy panoramic views of Ceuta and the Strait of Gibraltar."
        },
        {
          "title": "Attend Local Cultural Events",
          "details": "Check out winter festivals and events celebrating Ceuta's unique blend of cultures and traditions."
        },
        {
          "title": "Take a Day Trip to Nearby Gibraltar",
          "details": "Explore the iconic Rock of Gibraltar, just a short ferry ride away, offering unique sights and experiences."
        },
        {
          "title": "Stroll Through the Gardens of the Murallas Reales",
          "details": "Enjoy a peaceful walk through these historic gardens, featuring beautiful views and a serene environment."
        },
        {
          "title": "Shop at Local Markets",
          "details": "Browse local markets for unique crafts, spices, and products, perfect for picking up souvenirs."
        }
      ]
       },
    { code: "19", name: "Ciudad Autónoma de Melilla", tours:[
      {
        "title": "Melilla City Tour",
        "details": {
          "duration": "Full-day",
          "description": "Explore the unique blend of cultures in Melilla. Visit the historic old town, the impressive Royal Walls, and the beautiful Plaza de España, while learning about the city's diverse history.",
          "highlights": [
            "Royal Walls of Melilla",
            "Plaza de España",
            "Melilla La Vieja (Old Town)",
            "Local cuisine tasting"
          ]
        }
      },
      {
        "title": "Beach Day at Playa de la Hípica",
        "details": {
          "duration": "Half-day",
          "description": "Relax on the beautiful Playa de la Hípica, known for its clear waters and golden sands. Enjoy sunbathing, swimming, and beachside refreshments.",
          "highlights": [
            "Beach activities",
            "Sunbathing",
            "Local beach bars",
            "Water sports options"
          ]
        }
      },
      {
        "title": "Historical Tour of the Military Fortifications",
        "details": {
          "duration": "Half-day",
          "description": "Discover Melilla's military history with a tour of its historic fortifications. Visit the Fort of Victoria and the Fortress of Melilla, and learn about their strategic importance.",
          "highlights": [
            "Fort of Victoria",
            "Fortress of Melilla",
            "Guided historical insights",
            "Stunning panoramic views"
          ]
        }
      },
      {
        "title": "Day Trip to the Sierra de Alhamilla",
        "details": {
          "duration": "Full-day",
          "description": "Enjoy a nature-filled day trip to the Sierra de Alhamilla. Explore scenic trails, discover local flora and fauna, and take in breathtaking views of the surrounding landscape.",
          "highlights": [
            "Guided hiking trails",
            "Nature exploration",
            "Wildlife spotting",
            "Picnic lunch in nature"
          ]
        }
      },
      {
        "title": "Cultural Tour of the Melilla Museums",
        "details": {
          "duration": "Half-day",
          "description": "Visit some of Melilla's cultural highlights, including the Melilla Museum and the Interpretation Center of the Fortress. Learn about the city's history and heritage.",
          "highlights": [
            "Melilla Museum",
            "Interpretation Center of the Fortress",
            "Art and history exhibitions",
            "Local art galleries"
          ]
        }
      },
      {
        "title": "Excursion to the Moroccan Coast",
        "details": {
          "duration": "Full-day",
          "description": "Take a short boat trip to the nearby Moroccan coast. Visit the coastal town of Nador, experience local markets, and enjoy a taste of Moroccan cuisine.",
          "highlights": [
            "Boat trip to Nador",
            "Exploration of local markets",
            "Moroccan cuisine tasting",
            "Cultural exchange opportunities"
          ]
        }
      }
    ]
    , summer: [
        {
          "title": "Relax at the Beaches",
          "details": "Enjoy the sun and sea at popular beaches like Playa de la Hoya and Playa del Hipódromo, perfect for swimming and sunbathing."
        },
        {
          "title": "Explore the Historic City Center",
          "details": "Wander through the charming streets of Melilla, visiting historic sites like the Melilla La Vieja and the impressive fortress."
        },
        {
          "title": "Visit the Museum of Melilla",
          "details": "Discover the rich history and culture of the city at the local museum, featuring archaeological artifacts and art exhibitions."
        },
        {
          "title": "Enjoy Outdoor Dining",
          "details": "Savor delicious local cuisine at outdoor cafés and restaurants, where you can enjoy traditional dishes in a lively atmosphere."
        },
        {
          "title": "Attend Local Festivals and Events",
          "details": "Join in the summer festivities and cultural events, experiencing Melilla's vibrant community spirit and traditions."
        },
        {
          "title": "Hike in the Surrounding Natural Parks",
          "details": "Explore nearby natural parks like the Sierra de la Paloma, ideal for hiking and enjoying scenic views of the landscape."
        },
        {
          "title": "Visit the Lighthouse of Melilla",
          "details": "Take a trip to the historic lighthouse, which offers stunning views of the Mediterranean Sea and the coastline."
        },
        {
          "title": "Discover the Plaza de España",
          "details": "Relax in this central square, surrounded by beautiful architecture, cafes, and shops, perfect for people-watching."
        },
        {
          "title": "Enjoy Water Sports",
          "details": "Try activities like jet skiing, paddleboarding, or snorkeling at the beach for an adventurous day in the sun."
        },
        {
          "title": "Shop at Local Markets",
          "details": "Browse local markets for unique crafts, spices, and souvenirs, immersing yourself in the local culture and lifestyle."
        },
        {
          "title": "Explore the Botanical Garden",
          "details": "Visit the beautiful botanical garden, featuring a variety of plants and flowers, providing a peaceful retreat in summer."
        },
        {
          "title": "Take a Boat Trip",
          "details": "Enjoy a boat trip along the coastline, exploring hidden coves and enjoying the stunning Mediterranean views."
        }
      ]
      , winter: [
        {
          "title": "Explore Melilla La Vieja",
          "details": "Visit the historic fortified old town, enjoying its unique architecture and panoramic views of the surrounding area."
        },
        {
          "title": "Visit the Museum of Melilla",
          "details": "Discover the rich history and culture of Melilla at the local museum, featuring archaeological artifacts and art exhibitions."
        },
        {
          "title": "Attend Local Festivals",
          "details": "Experience the vibrant local festivals and events that take place during winter, showcasing Melilla's traditions and community spirit."
        },
        {
          "title": "Enjoy Traditional Cuisine",
          "details": "Warm up with hearty local dishes at cozy restaurants, savoring flavors such as tagine and fresh seafood."
        },
        {
          "title": "Stroll Through the Plaza de España",
          "details": "Relax in this central square, surrounded by beautiful architecture, shops, and cafés, perfect for enjoying the local atmosphere."
        },
        {
          "title": "Hike in the Surrounding Natural Parks",
          "details": "Take advantage of the mild winter weather to hike in nearby natural parks, enjoying scenic views and fresh air."
        },
        {
          "title": "Visit the Lighthouse of Melilla",
          "details": "Explore the historic lighthouse and take in stunning views of the Mediterranean, especially beautiful during winter sunsets."
        },
        {
          "title": "Explore Local Markets",
          "details": "Browse local markets for unique crafts, spices, and artisanal products, perfect for picking up souvenirs."
        },
        {
          "title": "Enjoy Cultural Performances",
          "details": "Attend theater performances, concerts, or other cultural events held in various venues across the city during winter."
        },
        {
          "title": "Discover the Botanical Garden",
          "details": "Visit the botanical garden to enjoy a peaceful walk among a variety of plants, providing a serene winter retreat."
        },
        {
          "title": "Take Scenic Drives",
          "details": "Enjoy the beautiful landscapes around Melilla with a scenic drive, appreciating the tranquility of the winter scenery."
        },
        {
          "title": "Relax at Local Cafés",
          "details": "Warm up with a hot drink at local cafés, where you can unwind and soak in the cozy winter atmosphere."
        }
      ]
       },
  ]